import { Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useCompanyDashboardContext } from '../../../../api/hooks'
import { Department } from '../../../../enums'
import Tooltip from '../../../../shared/components/Tooltip/Tooltip'
import * as Styled from './CompanyGamesTableStyles'

interface ProgressBarProps {
	values: { count: number[]; eligiblePlayers: number }
}

interface Row {
	id: string
	gameName: string
	gamesPercentage: { count: number[]; eligiblePlayers: number }
}

function CustomToolbar() {
	return (
		<GridToolbarContainer sx={{ height: '45px' }}>
			<Typography fontSize={20} paddingLeft={1}>
				Company Games
			</Typography>
		</GridToolbarContainer>
	)
}

function ProgressBar(props: ProgressBarProps) {
	const { values } = props

	return (
		<Styled.ProgressBarContainer>
			{(() => {
				const elements = []
				// eslint-disable-next-line no-plusplus
				for (let index = 0; index < values.count.length; index++) {
					const value = values.count[index]
					if (
						index === 0 &&
						(values.eligiblePlayers === 0 || value === values.eligiblePlayers)
					) {
						elements.push(
							<Styled.ProgressBarBar
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								style={{ width: `100%`, backgroundColor: '#ffffff' }}
							>
								<Styled.ProgressBarValue sx={{ color: '#000000' }}>
									This game has yet to be started
								</Styled.ProgressBarValue>
							</Styled.ProgressBarBar>,
						)
						break
					}
					if (value === 0) {
						// eslint-disable-next-line no-continue
						continue
					}

					let backgroundColor
					let message
					let percentage
					if (index === 0) {
						backgroundColor = 'rgba(241, 94, 90, 1)'
						message = `${value} player${value > 1 ? 's have' : ' has'} not completed a challenge.`
						percentage = Math.floor((value / values.eligiblePlayers) * 100)
					} else if (index === 1) {
						backgroundColor = 'rgba(253, 171, 90, 1)'
						message = `${value} player${value > 1 ? 's have' : ' has'} completed at least 1 challenge.`
						percentage = Math.ceil((value / values.eligiblePlayers) * 100)
					} else if (index === 2) {
						backgroundColor = 'rgba(181, 217, 157, 1)'
						message = `${value} player${value > 1 ? 's have' : ' has'} completed all challenges.`
						percentage = Math.ceil((value / values.eligiblePlayers) * 100)
					}

					elements.push(
						// eslint-disable-next-line react/no-array-index-key
						<Tooltip sx={{ width: '220px' }} key={index} title={message} arrow>
							<Styled.ProgressBarBar
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								style={{ width: `${percentage}%`, backgroundColor }}
							>
								<Styled.ProgressBarValue>{`${percentage} %`}</Styled.ProgressBarValue>
							</Styled.ProgressBarBar>
						</Tooltip>,
					)
				}
				return elements
			})()}
		</Styled.ProgressBarContainer>
	)
}

export function renderProgress(params: any) {
	return <ProgressBar values={params.value} />
}

const columns: GridColDef[] = [
	{
		field: 'gameName',
		headerName: 'Game',
		flex: 30,
	},
	{
		field: 'gamesPercentage',
		headerName: 'Completion',
		renderCell: renderProgress,
		type: 'number',
		align: 'center',
		headerAlign: 'center',
		flex: 70,
	},
]

function CompanyGamesTable({
	className,
	gamesData,
	department,
}: CompanyGamesActivitiesTableProps) {
	const [pageSize, setPageSize] = useState(10)
	// Initialised to dummy data for skeleton loading animation. Will be updated with real data on load.
	const [rows, setRows] = useState<Row[]>(
		Array.from({ length: pageSize }, (_, index) => ({
			id: `row-${index}`,
			gameName: 'Lorem ipsum',
			gamesPercentage: { count: [0, 1, 2], eligiblePlayers: 0 },
		})),
	)
	const { setOpenCompanyGameDialogInfo } = useCompanyDashboardContext()

	useEffect(() => {
		if (gamesData !== null) {
			const tempRows = []
			for (let index = 0; index < gamesData.exclusive_games.length; index += 1) {
				const game = gamesData.exclusive_games[index]

				if (
					!department ||
					department === Department.Default ||
					game.departments.some(dept => dept.name === department)
				) {
					tempRows.push({
						id: game.slug,
						gameName: game.title,
						gamesPercentage: {
							count: [
								game.inactive_players.length,
								game.eligible_players.length -
									game.completed_players.length -
									game.inactive_players.length,
								game.completed_players.length,
							],
							eligiblePlayers: game.eligible_players.length,
						},
					})
				}
			}
			setRows(tempRows)
		}
	}, [department, gamesData])

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	const onClickTableRow = (rowData: any) => {
		const selectedGame = gamesData?.exclusive_games.find(
			data => data.slug === rowData.row.id,
		)
		setOpenCompanyGameDialogInfo({
			isDialogShown: true,
			gameTitle: selectedGame!.title,
			gameSlug: selectedGame!.slug,
			gameDepartments: selectedGame!.departments,
		})
	}

	return (
		<Styled.TableBox className={className}>
			<DataGrid
				sx={{
					'& .MuiDataGrid-footerContainer': {
						height: '67px',
						minHeight: '67px',
						overflow: 'hidden',
					},
				}}
				disableRowSelectionOnClick
				rows={rows}
				autoHeight
				columns={columns}
				onRowCountChange={handlePageSizeChange}
				onRowClick={onClickTableRow}
				pageSizeOptions={[10, 25, 50, 100]}
				rowHeight={40}
				localeText={{
					MuiTablePagination: {
						labelRowsPerPage: '',
					},
				}}
				columnHeaderHeight={40}
				pagination
				initialState={{
					pagination: {
						paginationModel: { pageSize },
					},
				}}
				slots={{
					toolbar: CustomToolbar,
				}}
				slotProps={{
					loadingOverlay: {
						variant: 'skeleton',
						noRowsVariant: 'skeleton',
					},
				}}
				loading={gamesData === null}
			/>
		</Styled.TableBox>
	)
}
export default CompanyGamesTable
