import * as Styled from './CodeEditorStyles'
import useCodeEditor from '../FileExplorer/hooks/use-code-editor'
import { OnChange } from '../FileExplorer/hooks/on-update'

interface CodeEditorProps {
	fileContent: FileProps
	isEditable: boolean
	// eslint-disable-next-line react/require-default-props, no-unused-vars
	onChange?: OnChange
}

function CodeEditor({ fileContent, onChange, isEditable }: CodeEditorProps) {
	const fileExtension = fileContent.path.split('.')[1]

	const ref: any = useCodeEditor(
		fileContent.content,
		fileExtension,
		isEditable,
		onChange
	)

	return (
		<Styled.CodeEditorWrapper>
			<Styled.CodeEditor ref={ref} />
		</Styled.CodeEditorWrapper>
	)
}

export default CodeEditor
