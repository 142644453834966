import { ContentCopy } from '@mui/icons-material'
import { Box, Popover, ThemeProvider, Typography } from '@mui/material'
import copy from 'copy-to-clipboard'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	useChallenge,
	useRetrieveDirRepositoryContentsWS,
} from '../../../../../../api/hooks'
import playBackendRequestHandler from '../../../../../../api/playBackendRequestHandler'
import { setChallengeDialogPrompt } from '../../../../../../redux/challengeDialogPromptSlice'
import Loader from '../../../../../../shared/components/Loader/Loader'
import theme from '../../../../../../utils/theme'
import FileExplorer from '../../../../../Status/components/FileExplorer/FileExplorer'
import * as Styled from './CompanyPlayerRepositorySourceCodeStyles'
import ErrorPage from '../../../../../../shared/components/ErrorPage/ErrorPage'

function CompanyPlayerRepositorySourceCode({
	gameSlug,
	challengeSlug,
	companyPlayerUsername,
	companyPlayerEmail,
	codeRepoRepo,
	forkURI,
	isShown,
}: CompanyPlayerRepositorySourceCodeProps) {
	const dispatch = useDispatch()
	const challenge = useChallenge(gameSlug, challengeSlug)
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
	const handleCloseChallengeDescriptionDialog = () => {
		dispatch(setChallengeDialogPrompt(false))
	}
	const { loading: loadingRootContentsWS, dirContentsWSResponse } =
		useRetrieveDirRepositoryContentsWS(gameSlug, challengeSlug)
	const [dirContents, setDirContents] = useState<DirOrFileProps[]>(
		dirContentsWSResponse,
	)
	const [showCloneUrl, setShowCloneUrl] = useState(false)

	const handleCopyChallengeSolutionSSHUrl = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		challengeSolutionSSHUrl: string,
	) => {
		setAnchorEl(e.currentTarget)
		copy(challengeSolutionSSHUrl)
	}

	const handleDirOFileClick = useCallback(
		(filepath: string, type: string | null) => {
			if (filepath === '/') {
				playBackendRequestHandler(
					'companyAdminDirRepositoryContents',
					{
						game_slug: gameSlug,
						challenge_slug: challengeSlug,
						company_player_username: companyPlayerUsername,
						company_player_email: companyPlayerEmail,
						code_repo_repo: codeRepoRepo,
					},
					undefined,
					undefined,
				)
			} else if (type === 'dir' || type === null) {
				playBackendRequestHandler(
					'companyAdminDirRepositoryContents',
					{
						game_slug: gameSlug,
						challenge_slug: challengeSlug,
						company_player_username: companyPlayerUsername,
						company_player_email: companyPlayerEmail,
						code_repo_repo: codeRepoRepo,
						filepath,
					},
					undefined,
					undefined,
				)
			} else {
				playBackendRequestHandler(
					'companyAdminFileRepositoryContents',
					{
						game_slug: gameSlug,
						challenge_slug: challengeSlug,
						company_player_username: companyPlayerUsername,
						company_player_email: companyPlayerEmail,
						code_repo_repo: codeRepoRepo,
						filepath,
					},
					undefined,
					undefined,
				)
			}
		},
		[
			challengeSlug,
			codeRepoRepo,
			companyPlayerEmail,
			companyPlayerUsername,
			gameSlug,
		],
	)

	useEffect(() => {
		setDirContents(dirContentsWSResponse)
	}, [dirContentsWSResponse])

	const zeroingAnchor = () => {
		setAnchorEl(null)
	}

	// Get root contents of requested repository on load
	useEffect(() => {
		playBackendRequestHandler(
			'companyAdminDirRepositoryContents',
			{
				game_slug: gameSlug,
				challenge_slug: challengeSlug,
				company_player_username: companyPlayerUsername,
				company_player_email: companyPlayerEmail,
				code_repo_repo: codeRepoRepo,
			},
			undefined,
			undefined,
		)
	}, [
		challengeSlug,
		codeRepoRepo,
		companyPlayerEmail,
		companyPlayerUsername,
		gameSlug,
	])

	return (
		<Styled.SourceCodeContents
			fullWidth
			maxWidth='lg'
			onClose={handleCloseChallengeDescriptionDialog}
			open={isShown}
		>
			<Styled.AbsoluteClose onClick={handleCloseChallengeDescriptionDialog} />
			<Styled.SourceCodeContentsInnerWrapper>
				<ThemeProvider theme={theme}>
					<Styled.SourceCodeGridContainer container direction='column'>
						<Styled.SourceCodeGridItem
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								margin: '10px 50px 10px 50px',
							}}
						>
							<Styled.TitleText>
								{companyPlayerUsername}&apos;s {gameSlug}/{challengeSlug} Repository
							</Styled.TitleText>
							<Styled.CloneButton
								variant='outlined'
								onClick={() => {
									setShowCloneUrl(!showCloneUrl)
								}}
							>
								<Box>Clone</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center',
									}}
								>
									<Styled.CustomArrowDropDown open={showCloneUrl ? 1 : 0} />
								</Box>
							</Styled.CloneButton>
						</Styled.SourceCodeGridItem>
						<Styled.SourceCodeContentsWrapper>
							{challenge.isLoading ||
							loadingRootContentsWS ||
							dirContents.length === 0 ? (
								<Box sx={{ width: '200px', height: '400px' }}>
									<Loader />
								</Box>
							) : (
								<>
									<Styled.CustomCollapse in={showCloneUrl}>
										<Typography
											sx={{ marginTop: '10px' }}
											textAlign='center'
											variant='subtitle1'
										>
											You can <Styled.StrongText> git clone </Styled.StrongText> the
											following repository to view the player&apos;s code.
										</Typography>
										<Styled.CopyBoxWrapper
											onClick={e => handleCopyChallengeSolutionSSHUrl(e, forkURI)}
										>
											<Styled.CopyBox>
												<Styled.CopyBoxText
													variant='standard'
													id='forkURI'
													defaultValue={forkURI}
													InputProps={{ readOnly: true, disableUnderline: true }}
												/>
											</Styled.CopyBox>
											<Styled.CopyButton>
												<ContentCopy />
											</Styled.CopyButton>
										</Styled.CopyBoxWrapper>
										<Popover
											open={Boolean(anchorEl)}
											anchorEl={anchorEl}
											onClose={zeroingAnchor}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}
										>
											<Styled.CopiedText>Copied To Clipboard</Styled.CopiedText>
										</Popover>
									</Styled.CustomCollapse>
									<Styled.FileExplorerWrapper>
										{!challenge.isError ?
											<FileExplorer
												challenge={challenge.data?.data!}
												dirContents={dirContents}
												handleDirOFileClick={handleDirOFileClick}
												editable={false}
											/>
											:
											<ErrorPage
												errorIconName='VideogameAssetOff'
												errorTitle="Challenge Denied"
												errorMessage="You do not have permission to view this code."
												loadButtonWrapper="hidden"
												type="dialog"
											/>
										}
									</Styled.FileExplorerWrapper>
								</>
							)}
						</Styled.SourceCodeContentsWrapper>
					</Styled.SourceCodeGridContainer>
				</ThemeProvider>
			</Styled.SourceCodeContentsInnerWrapper>
		</Styled.SourceCodeContents>
	)
}

export default CompanyPlayerRepositorySourceCode
