const exportToCSV = (data: any[], filename: string = 'data.csv') => {
    if (!data || data.length === 0) {
        return;
    }

    // Convert JSON to CSV
    const csvHeaders = `${Object.keys(data[0]).join(',')}\n`;
    const csvRows = data.map(row => `${Object.values(row).join(',')}`).join('\n');
    const csvString = csvHeaders + csvRows;
    
    // Create a Blob
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary link and trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke URL after some time to free memory
    setTimeout(() => URL.revokeObjectURL(a.href), 30000);
};

export default exportToCSV