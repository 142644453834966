import { ArrowDropDown } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import {
	Box,
	Button,
	Collapse,
	Dialog,
	Grid,
	TextField,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const SourceCodeContents = styled(Dialog)({})

export const SourceCodeContentsInnerWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: '10px 40px 10px 40px',
	maxHeight: '1000px',
	maxWidth: '1200px',

	[theme.breakpoints.down('lg')]: {
		maxWidth: '100%',
	},
}))

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const TitleText = styled(Typography)({
	fontWeight: 'bold',
	textAlign: 'center',
	fontSize: '1.5rem',
	lineHeight: 1.235,
})

export const SourceCodeGridContainer = styled(Grid)({})

export const SourceCodeGridItem = styled(Box)({})

export const SourceCodeContentsWrapper = styled(Box)(() => ({
	marginBottom: '25px',
	alignContent: 'center',
	maxHeight: '800px',
	overflowY: 'scroll',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	alignItems: 'center',
}))

export const SourceCodeContentsText = styled(Typography)(({ theme }) => ({
	marginLeft: 10,
	color: theme.palette.secondary.main,
}))

export const CopyBoxWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	margin: '10px 0px 20px 0px',
	width: '600px',

	[theme.breakpoints.down('lg')]: {
		width: '500px',
	},
	[theme.breakpoints.down('md')]: {
		width: '400px',
	},
	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const CopyBox = styled(Box)({
	backgroundColor: '#F1F1F1',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	padding: '1rem',
	borderRadius: '20px 0 0 20px',
	cursor: 'pointer',
})

export const CopyBoxText = styled(TextField)({
	width: '100%',
	border: 'none',
})

export const CopyButton = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '1rem 1rem',
	color: `${theme.palette.common.white} !important`,
	borderRadius: '0 20px 20px 0',
	cursor: 'pointer',
}))

export const CopiedText = styled(Typography)({
	padding: 16,
})

export const StrongText = styled('strong')(({ theme }) => ({
	color: theme.palette.primary.main,
}))

export const FileExplorerWrapper = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '1040px',
	paddingBottom: '20px',
	overflowY: 'hidden',

	[theme.breakpoints.down('lg')]: {
		width: '800px',
	},
	[theme.breakpoints.down('mdm')]: {
		width: '750px',
	},
	[theme.breakpoints.down('md')]: {
		width: '550px',
	},
	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const CustomCollapse = styled(Collapse)(() => ({
	marginTop: '20px',
}))

export const CloneButton = styled(Button)(({ theme }) => ({
	flexShrink: 1,
	width: '110px',
	borderColor: theme.palette.primary.main,
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.common.white,
	fontSize: '0.75rem',
	fontWeight: 600,
	letterSpacing: '0.875px',

	'&:hover': {
		backgroundColor: theme.palette.common.white,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},

	[theme.breakpoints.down('ss')]: {
		marginTop: 15,
	},
}))

export const CustomArrowDropDown = styled(ArrowDropDown)<{ open: number }>(
	({ open }) => ({
		transition: 'transform 0.6s ease',
		transform: `${open ? 'rotate(180deg)' : 'none'}`,
		cursor: 'pointer',
		height: 15,
	}),
)
