import { Box, Typography, Button } from "@mui/material"
import { styled } from '@mui/material/styles'
import { ArrowBack } from '@mui/icons-material'

export const CustomArrowBackButton = styled(Button)(({ theme }) => (
    {
        float: 'left',
        minWidth: '0px!important',
        padding: '0px',
        margin: '5px 15px 6px 6px',
        background: 'none',
        '& > svg:hover': {
            color: theme.palette.primary.main,
            textDecoration: "underline",
            cursor: 'pointer',
            backgroundColor: "#F9F9F9",
        },
        '&:disabled > svg': {
            color: '#00000047!important'
        }

    }
))

export const CustomArrowBackIcon = styled(ArrowBack)(() => ({
    color: 'rgba(0, 0, 0, 0.87)',
}))

export const FileExplorerTopInfoRow = styled(Box)(({ theme }) => (
    {
        display: 'block',
        padding: 15,
        alignItems: "center",
        borderBottomStyle: "solid",
        borderColor: "#00000022",
        borderWidth: '2px',
        backgroundColor: '#F9F9F9',
        height: "65px",
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            height: "75px",
            display: 'flex',
            flexWrap: 'wrap',
            padding: '0px 15px',
        },
        [theme.breakpoints.down('ss')]: {
            height: "105px",
        }
    }
))

export const LastCommitId = styled(Typography)({
    overflow: 'hidden',
    padding: '4px 10px',
    backgroundColor: '#F1F1F1',
    color: '#616161',
    border: '1px solid',
    borderColor: '#91919144',
    borderRadius: 5,
    fontSize: '1rem',
    fontWeight: '500',
    float: 'left',
    margin: '3px 10px 0px 0px',
})

export const ArrowAndCommitSHAWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}))


export const LastCommitIdWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.down('md')]: {
        float: 'left',
    },
}))

export const FilePathBackTracking = styled(Box)(() => ({


}))
