import { Table, TableCell, Box, Grid, Typography } from "@mui/material"
import { styled } from '@mui/material/styles'
import { Edit } from '@mui/icons-material';

export const FileExplorerTable = styled(Table)({
    minWidth: 700
})

export const CustomEditIcon = styled(Edit)(({ theme }) => ({
    float: "right",
    position: "absolute",
    top: "19px",
    cursor: "pointer",
    fontSize: "25px",
    right: "25px",
    '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: 'pointer'
    },

    [theme.breakpoints.down('sm')]: {
        right: "10px",
    },

    [theme.breakpoints.down('xs')]: {
        right: "10px",
    },
}))

export const TableIconColumn = styled(TableCell)({
    width: 0
})

export const TableNameColumn = styled(TableCell)({
    width: 350
})

export const TableHead = styled(Box)(({ theme }) => ({
    width: '100%',
    color: theme.palette.primary.main,
    marginLeft: 20,
}))


export const CodeEditorContainer = styled(Box)(() => ({
    height: '500px',
    overflow: 'scroll',

    '& .cm-focused': {
        outline: 'none!important',
    },

    '& .cm-layer.cm-layer-above.cm-cursorLayer': {
        display: 'none'
    }
}))

export const LastCommit = styled(Grid)({
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 5,
})

export const ExplorerItem = styled(Typography)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: 'pointer'
    },
}))