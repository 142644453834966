import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 56px',
	paddingRight: '0',

	'& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
		outline: 'none !important',
	},

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	'& .MuiDataGrid-footerContainer': {
		height: '52px !important',
		minHeight: '0px',
	},

	'& .MuiTablePagination-displayedRows': {
		marginBlockStart: '0px !important',
		marginBlockEnd: '0px !important',
	},

	'& .css-7g6wwl-MuiInputBase-root-MuiTablePagination-select': {
		marginRight: '8px',
	},

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer',
	},
}))

export const StrongText = styled('strong')(({ theme }) => ({
	color: theme.palette.primary.main,
}))
