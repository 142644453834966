import {
    Box,
    Typography
} from '@mui/material'
import * as Styled from './FilePathBackTrackingStyles'

function FilePathBackTracking({ dirOrFilePath, challengeTitle, type, onClickFilePath }: FilePathBackTrackingProps) {
    const handleOnClickFilePath = (filePath: string, stepBack: number) => {
        let getFilePath = filePath
        if (filePath !== "/") {
            const splitPath: string[] = filePath.split("/")
            if (splitPath.length > 1) {
                for (let index = splitPath.length - 1; index > stepBack; index -= 1) {
                    splitPath.pop()
                }
                getFilePath = splitPath.join("/")
            }
            else {
                getFilePath = "/"
            }
        }
        onClickFilePath(getFilePath)
    }
    return <Styled.FilePathBackTracking>
        <Styled.FilePathLink onClick={() => { handleOnClickFilePath("/", 0) }} sx={{ float: "left" }}>{challengeTitle}</Styled.FilePathLink>
        <Typography sx={{ float: "left", margin: "1px 2px 0px 2px" }}>/</Typography>
        {
            // eslint-disable-next-line
            dirOrFilePath.split("/").slice(0, dirOrFilePath.split("/").length - (type === "dir" ? 2 : 1)).map((filePathItem: string, index: number) => <Box key={index} sx={{ float: "left" }}>
                <Styled.FilePathLink onClick={() => { handleOnClickFilePath(dirOrFilePath, index) }}>{filePathItem}</Styled.FilePathLink>
                <Typography sx={{ float: "left", margin: "1px 2px 0px 2px" }}>/</Typography>
            </Box>)}
        <Typography sx={{ float: "left", marginTop: "1.5px" }}>{dirOrFilePath.split("/")[dirOrFilePath.split("/").length - (type === "dir" ? 2 : 1)]}</Typography>
    </Styled.FilePathBackTracking>

}

export default FilePathBackTracking