/* eslint-disable no-nested-ternary */
import { Menu, MenuItem } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { OpenIDContext } from '../../api/auth';
import exportToCSV from '../../shared/helpers/exportToCSV';
import { CompanyDashboardContext, useCompany, useCompanyGamesActivities, useCompanyPlayersActivities, useCompanyPlayersActivitiesBarChart, useCompanyPlayersActivitiesGrade, useCompanyPlayersActivitiesPieChart } from "../../api/hooks";
import playBackendRequestHandler from '../../api/playBackendRequestHandler';
import { Department, TimePeriod } from '../../enums';
import { clearSelectedVulnerabilities } from '../../redux/vulnerabilitiesSlice';
import ErrorPage from '../../shared/components/ErrorPage/ErrorPage';
import Loader from '../../shared/components/Loader/Loader';
import SecDimAlert from '../../shared/components/SecDimAlert/SecDimAlert';
import SecDimButton from '../../shared/components/SecDimButton/SecDimButton';
import { getStartDateAndEndDateBasedOnTimePeriod } from '../../shared/helpers/utils';
import theme from '../../utils/theme';
import * as Styled from './CompanyDashboardStyles';
import CompanyDashboardInfo from './components/CompanyDashboardInfo/CompanyDashboardInfo';
import CompanyGameDialog from './components/CompanyGameDialog/CompanyGameDialog';
import CompanyGamesTable from './components/CompanyGamesTable/CompanyGamesTable';
import CompanyPlayerActivityDialog from './components/CompanyPlayerActivityDialog/CompanyPlayerActivityDialog';
import CompanyPlayersActivitiesBarChart from "./components/CompanyPlayersActivitiesBarChart/CompanyPlayersActivitiesBarChart";
import CompanyPlayersActivitiesFilter from './components/CompanyPlayersActivitiesFilter/CompanyPlayersActivitiesFilter';
import CompanyPlayersActivitiesPieChart from './components/CompanyPlayersActivitiesPieChart/CompanyPlayersActivitiesPieChart';
import CompanyPlayersActivitiesTable from './components/CompanyPlayersActivitiesTable/CompanyPlayersActivitiesTable';
import CompanyPlayersDepartmentFilter from './components/CompanyPlayersDepartmentFilter/CompanyPlayersDepartmentFilter';
import CreateDepartmentDialog from './components/CreateDepartmentDialog/CreateDepartmentDialog';

function CompanyDashboard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchCompanyData = useCompany()
    const fetchCompanyGamesActivitiesData = useCompanyGamesActivities()
    const fetchCompanyPlayersActivitiesData = useCompanyPlayersActivities()
    const fetchCompanyPlayersActivitiesBarChartData = useCompanyPlayersActivitiesBarChart()
    const fetchCompanyPlayersActivitiesPieChartData = useCompanyPlayersActivitiesPieChart()
    const fetchCompanyComparativeGradeData = useCompanyPlayersActivitiesGrade()
    const [companyData, setCompanyData] = useState<Company>()
    const [companyGamesActivitiesData, setCompanyGamesActivitiesData] = useState<CompanyGamesActivities | null>(null)
    const [companyPlayersActivitiesData, setCompanyPlayersActivitiesData] = useState<CompanyPlayersActivities | null>()
    const [companyPlayersActivitiesBarChartData, setCompanyPlayersActivitiesBarChartData] = useState<CompanyPlayersActivitiesBarChart[]>([])
    const [companyPlayersActivitiesPieChartData, setCompanyPlayersActivitiesPieChartData] = useState<CompanyPlayersActivitiesPieChart[]>([])
    const [companyComparativeGradeData, setCompanyComparativeGradeData] = useState<CompanyComparativeGrade>({ company_name: "", overall_company_comparative_total_score: 0, overall_company_comparative_z_score: 0, overall_company_comparative_percentile_rank: 0, department_grades: [] })
    const [openCompanyPlayerProfileDialogInfo, setOpenCompanyPlayerProfileDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "", playerGuid: "" })
    const [openCompanyGameDialogInfo, setOpenCompanyGameDialogInfo] = useState<{ isDialogShown: boolean, gameTitle: string, gameSlug: string, gameDepartments: any[]}>({ isDialogShown: false, gameTitle: '', gameSlug: '', gameDepartments: [],})
    const [isCreateDepartmentDialogOpened, setIsCreateDepartmentDialogOpened] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState(String(Department.Default))
    const [addMenuOpen, setAddMenuOpen] = useState<null | HTMLElement>(null);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(String(TimePeriod.Default));
    const [permissionAlert, setPermissionAlert] = useState<string>('')
    const [permissionAlertOpen, setPermissionAlertOpen] = useState(false)
    const [isPageError, setIsPageError] = useState(false)
    const [errorText, setErrorText] = useState<{title: string, message: string}>({ title: '', message: '' })
    const { authenticated, profile } = useContext(OpenIDContext)

    useEffect(() => {
        if (!isPageError && authenticated && !profile.restrictions.hasViewCompanyDashboardPermission) {
            setIsPageError(true)
			setErrorText({
				title: 'Company Dashboard',
				message: 'You do not have permission to view the company dashboard.',
			})
        }
    }, [isPageError, authenticated, profile.restrictions.hasViewCompanyDashboardPermission])

    // the following useEffect is for initializing companyData state following the Company api response
    useEffect(() => {
        if (fetchCompanyData?.data?.data) {
            setCompanyData(fetchCompanyData?.data?.data)
        }
    }, [fetchCompanyData?.data])

    // the following useEffect is for initializing the attempts table based on CompanyPlayersActivities api response
    useEffect(() => {
        if (fetchCompanyGamesActivitiesData?.data?.data) {
            setCompanyGamesActivitiesData(
                fetchCompanyGamesActivitiesData?.data.data,
            )
        }
    }, [fetchCompanyGamesActivitiesData?.data])
    
    // the following useEffect is for initializing the attempts table based on CompanyPlayersActivities api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesData?.data?.data) {
            setCompanyPlayersActivitiesData(fetchCompanyPlayersActivitiesData?.data.data)
        }
    }, [fetchCompanyPlayersActivitiesData?.data])

    useEffect(() => {
        if (fetchCompanyComparativeGradeData?.data?.data) {
            setCompanyComparativeGradeData(fetchCompanyComparativeGradeData?.data.data)
        }
    }, [fetchCompanyComparativeGradeData?.data])

    // the following useEffect is for initializing the bar chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesBarChartData?.data?.data) {
            const updatedData = fetchCompanyPlayersActivitiesBarChartData?.data?.data.map((stat) => {
                const matchedPlayer = companyData?.players?.find((player: any) => player.username === stat.player);
                return {
                    ...stat,
                    department: matchedPlayer ? matchedPlayer.department?.name : '-',
                };
            });
            setCompanyPlayersActivitiesBarChartData(updatedData)
        }
    }, [companyData?.players, fetchCompanyPlayersActivitiesBarChartData?.data])

    // the following useEffect is for initializing the pie chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesPieChartData?.data?.data) {
            setCompanyPlayersActivitiesPieChartData(fetchCompanyPlayersActivitiesPieChartData?.data?.data,)
        }
    }, [fetchCompanyPlayersActivitiesPieChartData?.data])

    // make api call with start date and end date and set the response to the attempts table
    const makeCompanyPlayersActivitiesAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivities',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyPlayersActivitiesData(resp.data)
            }
        })
    }

    // make api call with start date and end date and set the response to the games table
    const makeCompanyGamesActivitiesAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyGamesActivities',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyGamesActivitiesData(resp.data)
            }
        })
    }

    const refetchCompanyDashboardData = useCallback(() => {
        let getStartAndEndDate = null
        if (TimePeriod.Default !== selectedTimePeriod) {
            getStartAndEndDate = getStartDateAndEndDateBasedOnTimePeriod(selectedTimePeriod)
        }
        makeCompanyPlayersActivitiesAPICall(getStartAndEndDate)
        makeCompanyGamesActivitiesAPICall(getStartAndEndDate)
    }, [selectedTimePeriod])

    useEffect(() => {
        refetchCompanyDashboardData()
    }, [refetchCompanyDashboardData, selectedTimePeriod])

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAddMenuOpen(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAddMenuOpen(null);
    };

	useEffect(() => {
        // Set error variable when axios error is true, or loading and success are false
        const fetchCompanyGamesActivitiesDataError =
			fetchCompanyGamesActivitiesData.isError ||
			(!fetchCompanyGamesActivitiesData.isLoading && !fetchCompanyGamesActivitiesData.isSuccess)
		const fetchCompanyPlayersActivitiesDataError =
			fetchCompanyPlayersActivitiesData.isError ||
			(!fetchCompanyPlayersActivitiesData.isLoading && !fetchCompanyPlayersActivitiesData.isSuccess)
        const fetchCompanyPlayersActivitiesBarChartDataError =
            fetchCompanyPlayersActivitiesBarChartData.isError ||
            (!fetchCompanyPlayersActivitiesBarChartData.isLoading && !fetchCompanyPlayersActivitiesBarChartData.isSuccess)
        const fetchCompanyPlayersActivitiesPieChartDataError =
            fetchCompanyPlayersActivitiesPieChartData.isError ||
            (!fetchCompanyPlayersActivitiesPieChartData.isLoading && !fetchCompanyPlayersActivitiesPieChartData.isSuccess)
        const fetchCompanyComparativeGradeDataError =
            fetchCompanyComparativeGradeData.isError ||
            (!fetchCompanyComparativeGradeData.isLoading && !fetchCompanyComparativeGradeData.isSuccess)
		if (!isPageError && (fetchCompanyGamesActivitiesDataError || fetchCompanyPlayersActivitiesDataError || fetchCompanyPlayersActivitiesBarChartDataError || fetchCompanyPlayersActivitiesPieChartDataError || fetchCompanyComparativeGradeDataError)) {
            setIsPageError(true)
			setErrorText({
				title: 'Company Dashboard',
				message: 'An error occurred. Refresh the page or contact support.',
			})
		}
	}, [fetchCompanyPlayersActivitiesData, fetchCompanyPlayersActivitiesBarChartData, fetchCompanyPlayersActivitiesPieChartData, fetchCompanyComparativeGradeData, isPageError, fetchCompanyGamesActivitiesData.isError, fetchCompanyGamesActivitiesData.isLoading, fetchCompanyGamesActivitiesData.isSuccess])
    

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>
        {
            !isPageError && (fetchCompanyPlayersActivitiesBarChartData.isLoading || fetchCompanyPlayersActivitiesPieChartData.isLoading || fetchCompanyComparativeGradeData.isLoading) ?
                <Loader /> :
                isPageError ||
                    fetchCompanyGamesActivitiesData.isError || (!fetchCompanyGamesActivitiesData.isLoading && !fetchCompanyGamesActivitiesData.isSuccess) ||
                    fetchCompanyPlayersActivitiesData.isError || (!fetchCompanyPlayersActivitiesData.isLoading && !fetchCompanyPlayersActivitiesData.isSuccess) ||
                    fetchCompanyPlayersActivitiesBarChartData.isError || (!fetchCompanyPlayersActivitiesBarChartData.isLoading && !fetchCompanyPlayersActivitiesBarChartData.isSuccess) ||
                    fetchCompanyPlayersActivitiesPieChartData.isError || (!fetchCompanyPlayersActivitiesPieChartData.isLoading && !fetchCompanyPlayersActivitiesPieChartData.isSuccess) ||
                    fetchCompanyComparativeGradeData.isError || (!fetchCompanyComparativeGradeData.isLoading && !fetchCompanyComparativeGradeData.isSuccess) ?
                    <ErrorPage
                        errorIconName='Block'
                        errorTitle={errorText.title}
                        errorMessage={errorText.message}
                    /> :
                    <>
                        <SecDimAlert
                            isAlertShown={permissionAlertOpen}
                            setAlertShown={setPermissionAlertOpen}
                            severity='error'
                            alertMessage={permissionAlert}
                        />
                        <CreateDepartmentDialog
                            isOpenDialog={isCreateDepartmentDialogOpened}
                            handleCloseDialog={() => {
                                setIsCreateDepartmentDialogOpened(!isCreateDepartmentDialogOpened)
                            }}
                            refetchCompanyData={fetchCompanyData}
                        />
                        <CompanyDashboardContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{
                                openCompanyPlayerProfileDialogInfo,
                                setOpenCompanyPlayerProfileDialogInfo,
                                openCompanyGameDialogInfo,
                                setOpenCompanyGameDialogInfo,
                            }}>
                            {openCompanyPlayerProfileDialogInfo.isDialogShown && <CompanyPlayerActivityDialog companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} departments={companyData!.departments!} refetchCompanyData={fetchCompanyData} refetchCompanyDashboardData={refetchCompanyDashboardData} />}
                            {openCompanyGameDialogInfo.isDialogShown && <CompanyGameDialog companyData={companyData!} gamesActivityData={companyGamesActivitiesData?.exclusive_games.find((resp:CompanyGameResponse) => resp.slug === openCompanyGameDialogInfo.gameSlug) as CompanyGameResponse} />}
                            <Styled.DashboardHeaderAndFilterWrapper>
                                <Styled.Title variant='h1'>{companyComparativeGradeData?.company_name}</Styled.Title>
                                <Styled.DashboardHeaderButtonAndFilterWrapper>
                                    <SecDimButton rounded variant="outlined"
                                        sx={{
                                            backgroundColor: theme.palette.common.white,
                                            color: theme.palette.primary.main,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.common.white,
                                            }
                                        }}
                                        onClick={handleMenuOpen}
                                    ><Styled.CustomAddIcon />Add</SecDimButton>
                                    <Menu
                                        anchorEl={addMenuOpen}
                                        keepMounted
                                        open={Boolean(addMenuOpen)}
                                        onClose={handleMenuClose}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={() => {
                                            if (profile.restrictions.hasAddCompanyGamePermission) {
                                                dispatch(clearSelectedVulnerabilities())
                                                navigate('/create/game')
                                            } else {
                                                setPermissionAlertOpen(true)
                                                setPermissionAlert("Upgrade your account to manage company games.")
                                            }
                                            handleMenuClose();
                                        }}>
                                            <Styled.UserNavLink>
                                                <Styled.CustomGamepad />{' '}
                                                <Styled.UserNavLinkText>Game</Styled.UserNavLinkText>
                                            </Styled.UserNavLink>
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            if (profile.restrictions.hasAddCompanyDepartmentPermission) {
                                                setIsCreateDepartmentDialogOpened(!isCreateDepartmentDialogOpened)
                                            } else {
                                                setPermissionAlertOpen(true)
                                                setPermissionAlert("Upgrade your account to manage company departments.")
                                            }
                                            handleMenuClose();
                                        }}>
                                            <Styled.UserNavLink>
                                                <Styled.CustomBusiness />{' '}
                                                <Styled.UserNavLinkText>Department</Styled.UserNavLinkText>
                                            </Styled.UserNavLink>
                                        </MenuItem>
                                    </Menu>
                                    <CompanyPlayersDepartmentFilter company={companyData!} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
                                    <CompanyPlayersActivitiesFilter selectedTimePeriod={selectedTimePeriod} setSelectedTimePeriod={setSelectedTimePeriod} />
                                </Styled.DashboardHeaderButtonAndFilterWrapper>
                            </Styled.DashboardHeaderAndFilterWrapper>
                            <CompanyDashboardInfo companyPlayersActivitiesData={companyPlayersActivitiesData ?? null} companyComparativeGradeData={companyComparativeGradeData} department={selectedDepartment} />
                            <Styled.GraphTableContainer container>
                                <Styled.Graph container spacing={2}>
                                    <Styled.Graph item xs={12} sm={6}>
                                        <Styled.ExportBox mt={4.5}>
                                            <Styled.ExportTooltip title="Export CSV">
                                                <Styled.PieExport onClick={() => exportToCSV(companyPlayersActivitiesPieChartData, "pie-chart-data.csv")} color="primary">
                                                    <Styled.SaveAltIcon />
                                                </Styled.PieExport>
                                            </Styled.ExportTooltip>
                                        </Styled.ExportBox>
                                        <Styled.PieChartBox maxHeight={350}>
                                            <CompanyPlayersActivitiesPieChart companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} department={selectedDepartment} />
                                        </Styled.PieChartBox>
                                    </Styled.Graph>
                                    <Styled.Graph item xs={12} sm={6}>
                                        <CompanyPlayersActivitiesBarChart companyPlayersActivitiesBarChartData={companyPlayersActivitiesBarChartData} department={selectedDepartment} />
                                    </Styled.Graph>
                                </Styled.Graph>
                                <Styled.Table container spacing={2} marginTop={5}>
                                    <Styled.Table item xs={12} sm={12}>
                                        <CompanyGamesTable gamesData={companyGamesActivitiesData} department={selectedDepartment} />
                                    </Styled.Table>
                                </Styled.Table>
                                <Styled.Table item xs={12} sm={12} marginTop={5}>
                                    <CompanyPlayersActivitiesTable
                                        company={companyData!}
                                        companyPlayersActivitiesData={companyPlayersActivitiesData?.player_challenge_attempts ?? null} 
                                        department={selectedDepartment}
                                        refetchData={refetchCompanyDashboardData}
                                        setPermissionAlert={setPermissionAlert}
                                        setPermissionAlertOpen={setPermissionAlertOpen}
                                    />
                                </Styled.Table>
                            </Styled.GraphTableContainer>
                        </CompanyDashboardContext.Provider>
                    </>
        }
    </>
}


export default CompanyDashboard
