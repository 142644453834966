import FilePathBackTracking from '../FilePathBackTracking/FilePathBackTracking'
import * as Styled from './FileExplorerTopInfoRowStyles'

function FileExplorerTopInfoRow({ type, challengeTitle, path, container, latestCommit, onClickFilePath, onClickArrowBack }: FileExplorerTopInfoRowProps) {
    let disabled = true
    if (container !== "root" || type === "file") {
        disabled = false
    }
    return <Styled.FileExplorerTopInfoRow >
        <Styled.ArrowAndCommitSHAWrapper>
            <Styled.CustomArrowBackButton disableRipple disableFocusRipple disableTouchRipple disableElevation disabled={disabled}>
                <Styled.CustomArrowBackIcon onClick={() => { onClickArrowBack(path) }} />
            </Styled.CustomArrowBackButton>
            <Styled.LastCommitIdWrapper>
                {
                    latestCommit ? <Styled.LastCommitId variant="h5" align="left">{latestCommit.sha.slice(0, 8)}</Styled.LastCommitId> : null
                }
            </Styled.LastCommitIdWrapper>
            <Styled.FilePathBackTracking>
                {path ? <FilePathBackTracking challengeTitle={challengeTitle} type={type} dirOrFilePath={path} onClickFilePath={onClickFilePath} /> : null}
            </Styled.FilePathBackTracking>
        </Styled.ArrowAndCommitSHAWrapper>
    </Styled.FileExplorerTopInfoRow>
}

export default FileExplorerTopInfoRow;
