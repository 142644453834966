import {
    Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const FilePathLink = styled('a')(props => ({
    float: 'left',
    margin: '7px 3px 0px 3px',
    cursor: 'pointer',
    lineHeight: 1,
    color: props.theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline',
    },
}))

export const FilePathBackTracking = styled(Box)(({ theme }) => ({
    float: 'left',
    marginLeft: '0px',
    marginTop: '1.5px',

    [theme.breakpoints.down('md')]: {
        // marginLeft: '-210px',
        // marginTop: '32px'
    },
}))

