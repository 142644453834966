import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // yarn add date-fns chartjs-adapter-date-fns --save
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Department } from '../../../../enums';
import exportToCSV from '../../../../shared/helpers/exportToCSV';
import { MONTHS, arrayRange } from '../../../../shared/helpers/utils';
import theme from '../../../../utils/theme';
import * as Styled from './CompanyPlayersActivitiesBarChartStyles';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels
);

const getOptions = (updatedCompanyPlayersActivitiesBarChartData: any) => ({
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
			display: false
		},
		title: {
			display: true,
			text: 'Monthly Engagements'
		},
		tooltip: { /* The tooltip when you hover onto the dot */
			callbacks: {
				label: (context: any) =>  /* Label here means user_name, for example p1ch is a username, a dot is unique for each user */
					`total attempts: ${updatedCompanyPlayersActivitiesBarChartData[context.parsed.x].total_challenge_attempts}` /* Return the dot information */
				,
			},
		},
		datalabels: {
			display: false,
		}
	},
	scales: {
		x: { /* The X Axis scale measures the date */
			title: {
				display: true,
				text: 'Month',
			},
			// min: 0,
			// max: 11,
			grid: {
				display: false,
			},
		},
		y: { /* The Y Axis scale measures the score */
			title: {
				display: true,
				text: 'Attempts',
			},
			min: 0,
			max: 10000,
			ticks: {
				stepSize: 5,
			},
			grid: {
				display: false,
			},
		},
	},
})

const filledCompanyPlayersActivitiesBarChartData = (
	minMonthIndex: number,
	maxMonthIndex: number,
	companyPlayersActivitiesBarChartData: any,
) => {
	/*
        arrayRange basically returns an array of range min and max, min 0, max 2, returns = [0. 1, 2]
        the purpose of filledCompanyPlayersActivitiesBarChartData is let's say, backend return 2 months data, one is Jan and another is Jul
        there is 4 months gap, between Jan and Jul, this filledCompanyPlayersActivitiesBarChartData method, basically, fills that up with total_challenge_attempts 0, and put those gap months
        this gap filling is required for hovering on the bar 
    */
	const aggregatedData = companyPlayersActivitiesBarChartData.reduce(
		(acc: any, curr: any) => {
			const key = `${curr.year}-${curr.month}`
			if (!acc[key]) {
				acc[key] = { ...curr }
			} else {
				acc[key].total_challenge_attempts += curr.total_challenge_attempts
			}
			return acc
		},
		{},
	)

	const updatedCompanyPlayersActivitiesBarChartData: CompanyPlayersActivitiesBarChart[] = []
	const years = Array.from(
		new Set(companyPlayersActivitiesBarChartData.map((data: any) => data.year)),
	) as number[]

	years.forEach(year => {
		arrayRange(minMonthIndex, maxMonthIndex, 1).forEach(monthIndex => {
			const key = `${year}-${monthIndex}`
			if (aggregatedData[key]) {
				updatedCompanyPlayersActivitiesBarChartData.push(aggregatedData[key])
			} else {
				updatedCompanyPlayersActivitiesBarChartData.push({
					player: '',
					total_challenge_attempts: 0,
					month: monthIndex,
					year
				})
			}
		})
	})
	return updatedCompanyPlayersActivitiesBarChartData
}

const populateSortedCompanyPlayersActivitiesBarChartData = (companyPlayersActivitiesBarChartData: CompanyPlayersActivitiesBarChart[]) => {
	const minMonthIndex = companyPlayersActivitiesBarChartData[0] ? companyPlayersActivitiesBarChartData[0].month : 0
	const maxMonthIndex = companyPlayersActivitiesBarChartData[companyPlayersActivitiesBarChartData.length - 1] ? companyPlayersActivitiesBarChartData[companyPlayersActivitiesBarChartData.length - 1].month : 0
	let updatedCompanyPlayersActivitiesBarChartData: CompanyPlayersActivitiesBarChart[] = []
	if (minMonthIndex < maxMonthIndex) {
		updatedCompanyPlayersActivitiesBarChartData = filledCompanyPlayersActivitiesBarChartData(
			minMonthIndex,
			maxMonthIndex,
			companyPlayersActivitiesBarChartData)
	}
	else {
		/*
			minMonthIndex > maxMonthIndex, means companyPlayersActivitiesBarChartData has two years data, the following reduce, categorize those data based on the year
			example: {
				2022: [1,2,3],
				2021: [3,4,5]
			}
		*/
		const categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear = companyPlayersActivitiesBarChartData.reduce((r, a) => {
			// eslint-disable-next-line no-param-reassign
			r[a.year] = r[a.year] || [];
			r[a.year].push(a);
			return r;
		}, Object.create(null));
		/* now iterate that categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear object, and fill up the missing months inside each year data array by calling 
			filledCompanyPlayersActivitiesBarChartData and combine each year filled month data in the single updatedCompanyPlayersActivitiesBarChartData array */
		// eslint-disable-next-line no-restricted-syntax
		for (const key in categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear) {
			if (Object.hasOwn(categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear, key)) {
				updatedCompanyPlayersActivitiesBarChartData = [...updatedCompanyPlayersActivitiesBarChartData, ...filledCompanyPlayersActivitiesBarChartData(
					categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear[key][0].month,
					categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear[key][categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear[key].length - 1].month,
					categorizeCompanyPlayersActivitiesBarChartDataBasedOnYear[key]
				)]
			}

		}
	}
	return updatedCompanyPlayersActivitiesBarChartData
}

/* This is the function that gets the information for the bar chart based on our given data set */
const getBarChartInfo = (companyPlayersActivitiesBarChartData: CompanyPlayersActivitiesBarChart[], department: string) => {
	// Filter data by department if provided 
	if (department !== Department.Default) {
		// eslint-disable-next-line no-param-reassign
		companyPlayersActivitiesBarChartData = companyPlayersActivitiesBarChartData.filter(data => data.department === department);
	}
	// sort companyPlayersActivitiesBarChartData based on year and then month
	const sortedCompanyPlayersActivitiesBarChartData = companyPlayersActivitiesBarChartData.sort((a, b) => a.year.toString().localeCompare(b.year.toString()) || a.month - b.month);
	// populate companyPlayersActivitiesBarChartData and explanation was given in the comment section inside the populateCompanyPlayersActivitiesBarChartData method
	const updatedCompanyPlayersActivitiesBarChartData = populateSortedCompanyPlayersActivitiesBarChartData(sortedCompanyPlayersActivitiesBarChartData)
	const options = getOptions(updatedCompanyPlayersActivitiesBarChartData)
	const borderColor = theme.palette.primary.main
	const backgroundColor = theme.palette.primary.main

	const datasets: { /* Extract the data from the given dataset, Label being the name of the user with a unique color and the data being the coordinates of where to place the dot */
		data: { x: string; y: number }[] /* The X Axis scale measures the date, The Y Axis scale measures the score */
		borderColor: string
		backgroundColor: string
	}[] = []

	let maxAttempts: number = 0 // to set max value for y axis

	if (updatedCompanyPlayersActivitiesBarChartData && updatedCompanyPlayersActivitiesBarChartData.length > 0) {
		const data: { x: string; y: number }[] = []
		for (let i = 0; i < updatedCompanyPlayersActivitiesBarChartData.length; i += 1) {
			if (maxAttempts < updatedCompanyPlayersActivitiesBarChartData[i].total_challenge_attempts) {
				maxAttempts = updatedCompanyPlayersActivitiesBarChartData[i].total_challenge_attempts
			}
			data.push({
				x: MONTHS[updatedCompanyPlayersActivitiesBarChartData[i].month],
				y: updatedCompanyPlayersActivitiesBarChartData[i].total_challenge_attempts,
			})
		}
		datasets.push({
			data: [...data],
			borderColor,
			backgroundColor,
		})
	}
	options.scales.y.max = maxAttempts + 5
	return {
		options,
		labels: updatedCompanyPlayersActivitiesBarChartData.map((item: any) => MONTHS[item.month]),
		datasets,
	}
}


function CompanyPlayersActivitiesBarChart({ className, companyPlayersActivitiesBarChartData, department }: CompanyPlayersActivitiesBarChartProps) {
	const barChartInfo = getBarChartInfo(companyPlayersActivitiesBarChartData, department)
	return (
		<Styled.BarChartBox className={className}>
			<Styled.ExportBox>
				<Styled.ExportTooltip title="Export CSV">
					<Styled.BarExport onClick={() => exportToCSV(companyPlayersActivitiesBarChartData, "bar-chart-data.csv")} color="primary">
						<Styled.SaveAltIcon />
					</Styled.BarExport>
				</Styled.ExportTooltip>
			</Styled.ExportBox>
			<Bar options={barChartInfo.options} data={{ labels: barChartInfo.labels, datasets: barChartInfo.datasets }} />
		</Styled.BarChartBox>
	)
}
export default CompanyPlayersActivitiesBarChart
