import { styled } from '@mui/material/styles'

export const CodeEditorWrapper = styled('div')({
	height: '100%',
})

export const EditFileHeader = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: -2,
	padding: 10,
	border: `2px solid ${theme.palette.grey[100]}`,
	borderRadius: '4px 4px 0 0',
	borderBottomWidth: 0,

	'& > svg': {
		width: 18,
		height: 18,
		marginRight: 10,
	},
}))

export const CodeEditor = styled('div')(({ theme }) => ({
	height: '100%',
	border: `2px solid ${theme.palette.grey[100]}`,
	borderRadius: 4,
}))
