import { Box, IconButton, Tooltip } from '@mui/material'
import { SaveAlt } from '@mui/icons-material';
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const BarChartBox = styled(Box)(() => ({
    marginTop: 30,
}))

export const BarExport = styled(IconButton)(() => ({
    "&:hover": {
        backgroundColor: "transparent !important",
        boxShadow: "none",
        color: "inherit",
    },
}))

export const SaveAltIcon = styled(SaveAlt)(() => ({

}))

export const ExportTooltip = styled(Tooltip)(() => ({

}))

export const ExportBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: 8,
}))

