import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import * as Styled from './CompanyPlayerRepositoriesTableStyles'
import playBackendRequestHandler from '../../../../../../api/playBackendRequestHandler'
import { useChallengeSolutionForkUrlWSWithoutGameAndChallengeSlug, useCompanyDashboardContext } from '../../../../../../api/hooks'
import { getStatusStateInText } from '../../../../../../shared/helpers/utils'
import { setChallengeDialogPrompt } from '../../../../../../redux/challengeDialogPromptSlice'
import CompanyPlayerRepositorySourceCode from '../CompanyPlayerRepositorySourceCode/CompanyPlayerRepositorySourceCode'

const columns: GridColDef[] = [
	{
		field: 'gameTitle',
		headerName: 'Game',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
	{
		field: 'challengeTitle',
		headerName: 'Challenge',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
	{
		field: 'challengeStatus',
		headerName: 'Status',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	}
]

function CompanyPlayerRepositoriesTable() {
	const dispatch = useDispatch()
	const [pageSize, setPageSize] = useState(5)
	const rows: { gameTitle: string; challengeTitle: string; challengeStatus: string }[] = []
	const [companyPlayerRepositories, setCompanyPlayerRepositories] = useState<CompanyPlayerRepository[]>([])
	const { openCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const isIDEDialogVisible = useSelector((state: any) => state.challengeDialogPrompt.showDialog)
	const { challengeSolutionSSHUrlResponse, setChallengeSolutionSSHUrlResponse } = useChallengeSolutionForkUrlWSWithoutGameAndChallengeSlug()
	const [selectedPlayerRepoDetails, setSelectedPlayerRepoDetails] = useState({gameSlug: '', challengeSlug: '', companyPlayerUsername: '', companyPlayerEmail: '', codeRepoRepo: ''})
	
	const makeCompanyAdminGitCollaborationAddApiCall = (companyPlayerUsername: string, companyPlayerEmail: string, codeRepo: string) => playBackendRequestHandler(
		'companyAdminGitCollaborationAdd',
		{
			company_player_email: companyPlayerEmail,
			company_player_username: companyPlayerUsername,
			code_repo_repo: codeRepo
		},
		undefined,
		undefined
	)

	const makeCompanyPlayerRepositoriesApiCall = (playerUsername: string, playerEmail: string) => playBackendRequestHandler(
		'companyPlayerRepositories',
		undefined,
		undefined,
		`?company_player_username=${playerUsername}&company_player_email=${playerEmail}`
	).then(resp => {
		if (resp.status === 200) {
			setCompanyPlayerRepositories(resp.data as any)
		}
	})

	// the following useEffect is to call repositories api, get the repo list data and finally render user repositories table
	useEffect(() => {
		if (openCompanyPlayerProfileDialogInfo.playerUsername && openCompanyPlayerProfileDialogInfo.playerEmail)
			makeCompanyPlayerRepositoriesApiCall(openCompanyPlayerProfileDialogInfo.playerUsername, openCompanyPlayerProfileDialogInfo.playerEmail)
	}, [openCompanyPlayerProfileDialogInfo])

	// the following useEffect is to set challengeSolutionSSHUrlResponse when it arrives through ws.
	// this comes when company admin clicks on a player repository from the table
	useEffect(() => {
		if (challengeSolutionSSHUrlResponse) {
			dispatch(setChallengeDialogPrompt(true))
		}
	}, [challengeSolutionSSHUrlResponse, dispatch])

	// the following useEffect is to reset challengeSolutionSSHUrlResponse value to null string when admin close gitCloneIDE dialog and the value of isIDEDialogVisible is false
	useEffect(() => {
		if (!isIDEDialogVisible) {
			setChallengeSolutionSSHUrlResponse('')
		}
	}, [isIDEDialogVisible, setChallengeSolutionSSHUrlResponse])

	const onClickPlayerRepoRow = (rowData: any) => {
		const companyPlayerRepository = companyPlayerRepositories.find((item) => item.game_title === rowData.row.gameTitle && item.challenge_title === rowData.row.challengeTitle)
		makeCompanyAdminGitCollaborationAddApiCall(openCompanyPlayerProfileDialogInfo.playerUsername, openCompanyPlayerProfileDialogInfo.playerEmail, companyPlayerRepository?.code_repo_repo!)
		setSelectedPlayerRepoDetails({
			gameSlug: companyPlayerRepository?.game_slug!,
			challengeSlug: companyPlayerRepository?.challenge_slug!,
			companyPlayerUsername: openCompanyPlayerProfileDialogInfo.playerUsername,
			companyPlayerEmail: openCompanyPlayerProfileDialogInfo.playerEmail,
			codeRepoRepo: companyPlayerRepository?.code_repo_repo!
		})
	}

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	// the following for loop is to populate rows with companyPlayerRepositories response data to render table 
	for (let index = 0; index < companyPlayerRepositories.length; index += 1) {
		rows.push({
			gameTitle: companyPlayerRepositories[index].game_title,
			challengeTitle: companyPlayerRepositories[index].challenge_title,
			challengeStatus: getStatusStateInText(companyPlayerRepositories[index].challenge_status)
		})
	}
	return (
		<>
			{isIDEDialogVisible && (
				<CompanyPlayerRepositorySourceCode
					gameSlug={selectedPlayerRepoDetails.gameSlug}
					challengeSlug={selectedPlayerRepoDetails.challengeSlug}
					companyPlayerUsername={selectedPlayerRepoDetails.companyPlayerUsername}
					companyPlayerEmail={selectedPlayerRepoDetails.companyPlayerEmail}
					codeRepoRepo={selectedPlayerRepoDetails.codeRepoRepo}
					forkURI={challengeSolutionSSHUrlResponse}
					isShown={isIDEDialogVisible}
				/>
			)}
			<Styled.TableBox>
				<DataGrid
					style={{ fontSize: 16 }}
					getRowId={(row: any) => row.gameTitle + row.challengeTitle}
					rows={rows}
					columns={columns}
					onRowCountChange={handlePageSizeChange}
					pageSizeOptions={[5, 10, 25, 50, 100]}
					autoHeight
					rowHeight={35}
					localeText={{
						MuiTablePagination: {
							labelRowsPerPage: '',
						},
					}}
					columnHeaderHeight={35}
					pagination
					disableRowSelectionOnClick
					initialState={{
						pagination: {
							paginationModel: { pageSize },
						},
					}}
					onRowClick={onClickPlayerRepoRow}
				/>
			</Styled.TableBox>
		</>
	)
}
export default CompanyPlayerRepositoriesTable
