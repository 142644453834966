import { Close } from '@mui/icons-material'
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const SecdimBlue = styled('span')(({ theme }) => ({
	color: `${theme.palette.primary.main}!important`,
	fontWeight: 'bold',
}))

export const CustomDialog = styled(Dialog)({})

export const PlayerActivityGridDialogItem = styled(Grid)({})

export const PlayerRankingsTitle = styled(Typography)(({ theme }) => ({
	marginTop: 10,
	padding: '0px 0px 10px 75px',
	fontWeight: 600,
	textAlign: 'center',
	fontSize: '13.5px',
	color: '#464343',

	[theme.breakpoints.down('sm')]: {
		padding: '0px 0px 10px 0px',
		textAlign: 'center',
		marginTop: 0,
	},
}))

export const PlayerActivityGraphTableContainer = styled(Grid)(() => ({
	margin: '10px 0px 15px 0px',
	width: '900px !important',
}))

export const PlayerActivityGraphTableItem = styled(Grid)(() => ({}))

export const SquareColorLabelWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	paddingLeft: '43px',

	[theme.breakpoints.down('lg')]: {
		paddingLeft: '39px',
	},
	[theme.breakpoints.down('mdm')]: {
		paddingLeft: '32px',
	},
	[theme.breakpoints.down('md')]: {
		paddingLeft: '29px',
	},
}))

export const SquareColorLabel = styled(Box, {
	shouldForwardProp: prop => prop !== 'backgroundColor',
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
	height: 14.71,
	width: 14.71,
	backgroundColor,
	marginRight: '1.5px',
}))

export const DialogTitleText = styled(DialogTitle)({
	fontWeight: 'bold',
	textAlign: 'center',
	fontSize: '1.875rem',
	lineHeight: 1.235,
})

export const ButtonWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
})

export const GitActivities = styled(Typography)(() => ({
	fontSize: '12px',
	paddingRight: '40px',
}))

export const LegendWrapper = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-start',
	gap: '20px',
}))

export const PlayerActivityDialogContainer = styled(Grid)(({ theme }) => ({
	width: 1000,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 40,

	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const UserNavLink = styled('a')(({ theme }) => ({
	color: theme.palette.primary.main,
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'underline',
	},
}))

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const CustomCalendarHeatmapWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	'& .react-calendar-heatmap': {
		width: '900px !important',
		marginBottom: '-30px',
	},

	[theme.breakpoints.down('lg')]: {
		'& .react-calendar-heatmap': {
			width: '800px !important',
		},
	},
	[theme.breakpoints.down('mdm')]: {
		'& .react-calendar-heatmap': {
			width: '650px !important',
		},
	},
	[theme.breakpoints.down('md')]: {
		'& .react-calendar-heatmap': {
			width: '600px !important',
		},
	},
}))

export const RemoveUserButton = styled(Button)({
	padding: '6px 14px',
	borderColor: '#ff000073',
	backgroundColor: '#FFF',
	color: '#ff0000de',
	'&:hover': {
		color: '#FFF',
		backgroundColor: '#ff0000de',
		borderColor: '#ff000073',
	},
})
