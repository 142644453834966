import { Table, TableCell, Box, Grid, Typography, Button, TextareaAutosize } from "@mui/material"
import { styled } from '@mui/material/styles'
import { ArrowBack } from '@mui/icons-material';

export const FileExplorerTable = styled(Table)({
    minWidth: 700
})

export const CustomArrowBackIcon = styled(ArrowBack)(({ theme }) => ({
    float: "left",
    position: "absolute",
    top: "19px",
    cursor: "pointer",
    left: "15px",

    '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: 'pointer'
    },
}))

export const FilePathBackTracking = styled(Box)({
    float: "left",
    marginTop: "2px"
})

export const TableIconColumn = styled(TableCell)({
    width: 0
})

export const TableNameColumn = styled(TableCell)({
    width: 350
})

export const TableHead = styled(Box)(({ theme }) => ({
    width: '100%',
    color: theme.palette.primary.main,
    marginLeft: 20,
}))

export const TopInfoRow = styled(Grid)(({ theme }) => (
    {
        padding: 15,
        alignItems: "center",
        borderBottomStyle: "solid",
        borderColor: "#00000022",
        borderWidth: '2px',
        backgroundColor: '#F9F9F9',
        position: "relative",
        height: "65px",
        [theme.breakpoints.down('md')]: {
            height: "90px",
        },
    }
))

export const AvatarNameCommit = styled(Grid)({
    alignItems: "center",
})

export const LastCommit = styled(Grid)({
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: 5,
})

export const LastCommitId = styled(Typography)({
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: '#F1F1F1',
    color: '#616161',
    border: '1px solid',
    borderColor: '#91919144',
    borderRadius: 5,
    fontSize: '1rem',
    fontWeight: '500',
    float: "left",
    marginRight: "10px"
})

export const EditBox = styled(TextareaAutosize)(() => ({
    height: '810px!important',
    overflow: 'scroll',
    fontSize: "1.125rem",
    lineHeight: "1.7",
    fontFamily: "Averta,sans-serif",
    fontWeight: "400",
    border: "none",
    width: "100%",
    outline: "none",
    padding: "10px",
    resize: "none"
}))

export const ExplorerRowButton = styled(Box)(({ theme }) => ({
    borderColor: "#00000022",
    borderWidth: "2px",
    height: "80px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    borderTopStyle: "solid",
    position: "relative",

    [theme.breakpoints.down('md')]: {
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        display: "flex",
        flexDirection: "column",
        margin: '0px 20px'
    },

}))

export const FilePathLink = styled('a')((props) => ({
    float: "left",
    margin: '7px 3px 0px 3px',
    cursor: 'pointer',
    lineHeight: 1,
    color: props.theme.palette.primary.main,
    '&:hover': {
        textDecoration: "underline",
    },
}));

export const CodeEditorContainer = styled(Box)(() => ({
    height: '500px',
    overflowY: 'scroll'
}))

export const CodeEditorButtonsContainer = styled(Box)(({ theme }) => ({
    height: '80px',
    backgroundColor: "#F9F9F9",

    [theme.breakpoints.down('md')]: {
        height: '180px',
        overflowY: 'visible',
    },
}))

export const FileContentCommitButton = styled(Button)(({ theme }) => ({
    width: 144,
    marginRight: 20,
    padding: 12,
    borderColor: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.875px',

    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 0,
        marginTop: 12,
    },
}));

export const FileContentCommitAndTestButton = styled(Button)(({ theme }) => ({
    width: 144,
    margin: "0px 20px",
    padding: 12,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.875px',

    '&:hover': {
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 0,
        marginTop: 105,
    },
}));

export const FileContentCancelButton = styled(Button)(({ theme }) => ({
    width: 144,
    marginRight: 20,
    padding: 12,
    borderColor: "#ff000073 !important",
    fontSize: '0.75rem',
    color: "#ff0000de !important",
    fontWeight: 600,
    letterSpacing: '0.875px',
    position: "absolute",
    right: "0px",

    '&:hover': {
        backgroundColor: "#ff00000d !important",
    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
        position: "relative",
        right: "auto",
        margin: '8px 0px',
    },
}));
