import { Paper, TableContainer } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useRetrieveFileContentWS } from '../../../../api/hooks'
import FileExplorerTopInfoRow from '../../../../shared/components/FileExplorer/FileExplorerTopInfoRow/FileExplorerTopInfoRow'
import CodeEditor from '../CodeEditor/CodeEditor'
import FileContentUpdate from '../FileContentUpdate/FileContentUpdate'
import * as Styled from './FileContentStyles'

function FileContent({
	challenge,
	fileContent,
	latestCommit,
	dirContents,
	handleDirOFileClick,
	goBackFileExplorer,
	continueTour,
	editable
}: FileContentProps) {
	const { loading, fileContentsWSResponse } = useRetrieveFileContentWS(
		fileContent.gameSlug,
		fileContent.challengeSlug,
	)
	const [displayFileContentUpdate, setDisplayFileContentUpdate] =
		useState<boolean>(false)
	const [fileContentWS, setFileContentWS] = useState<FileProps>(
		fileContentsWSResponse,
	)

	useEffect(() => {
		setFileContentWS(fileContentsWSResponse)
	}, [fileContentsWSResponse])

	const onClickArrowBack = (filePath: string) => {
		let getFilePath = filePath
		const splitPath: string[] = filePath.split('/')
		if (splitPath.length > 1) {
			// means navigating to sub-directories
			splitPath.pop() // for one step back
			getFilePath = splitPath.join('/')
		} else {
			getFilePath = '/'
		}
		handleDirOFileClick(getFilePath, null)
		goBackFileExplorer(false)
	}

	const onClickFilePath = (backTrackedPath: string) => {
		handleDirOFileClick(backTrackedPath, null)
		goBackFileExplorer(false)
	}

	const clickOnEditButton = (filePath: string) => {
		setDisplayFileContentUpdate(true)
		handleDirOFileClick(filePath, 'file')
	}

	const clickCancelUpdateBtn = useCallback(() => {
		setDisplayFileContentUpdate(false)
	}, [])

	// Continue tour when the Commit/Commit & Test buttons are shown
	useEffect(() => {
		if (continueTour && !loading && displayFileContentUpdate) {
			continueTour(true)
		}
	})

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{!loading && displayFileContentUpdate ? (
				<FileContentUpdate
					challenge={challenge}
					fileContent={fileContentWS}
					latestCommit={latestCommit}
					handleDirOFileClick={handleDirOFileClick}
					clickCancelUpdateBtn={clickCancelUpdateBtn}
					goBackFileExplorer={goBackFileExplorer}
				/>
			) : (
				<TableContainer
					component={Paper}
					sx={{ borderRadius: '0 0 20 20', marginTop: '20px', position: 'relative' }}
				>
					{dirContents && dirContents.length > 0 ? (
						<FileExplorerTopInfoRow
							challengeTitle={challenge.title}
							path={fileContent.path}
							container={dirContents[0].container}
							type='file'
							latestCommit={latestCommit}
							onClickArrowBack={onClickArrowBack}
							onClickFilePath={onClickFilePath}
						/>
					) : null}
					{editable ? (
						<Styled.CustomEditIcon
							className='tourEditButton'
							onClick={() => clickOnEditButton(fileContent.path)}
						/>
					) : null}
					<Styled.CodeEditorContainer>
						<CodeEditor
							fileContent={fileContent}
							isEditable={displayFileContentUpdate}
						/>
					</Styled.CodeEditorContainer>
				</TableContainer>
			)}
		</>
	)
}

export default FileContent
