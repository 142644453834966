import { useEffect } from 'react'
import onUpdate, { OnChange } from './on-update'
import useCodeMirror from './use-code-mirror'

const useCodeEditor = (
	content: string,
	fileExtension: string,
	isEditable: boolean,
	onChange?: OnChange
) => {
	const { ref, view } = useCodeMirror(content, fileExtension, isEditable, [
		onUpdate(content, onChange),
	])

	useEffect(() => {
		if (view) {
			const editorValue = view?.state.doc.toString()

			if (content !== editorValue) {
				view.dispatch({
					changes: {
						from: 0,
						to: editorValue.length,
						insert: content || '',
					},
				})
			}
		}
	}, [content, view])

	return ref
}

export default useCodeEditor
