import { useCallback, useEffect, useState } from 'react'
import { TableContainer, Paper } from '@mui/material'
import * as Styled from './FileContentUpdateStyles'
import {
	useUpdateFileRepositoryContentsRequest,
	useUpdateFileRepositoryContentsWS,
	useRetrieveFileContentRequest,
	useStatusContext,
} from '../../../../api/hooks'
import CodeEditor from '../CodeEditor/CodeEditor'
import FileExplorerTopInfoRow from '../../../../shared/components/FileExplorer/FileExplorerTopInfoRow/FileExplorerTopInfoRow'
import { AutoTestState, ChallengeType } from '../../../../enums'

function FileContentUpdate({
	fileContent,
	latestCommit,
	handleDirOFileClick,
	clickCancelUpdateBtn,
	goBackFileExplorer,
	challenge
}: FileContentUpdateProps) {
	const { isUpdated } = useUpdateFileRepositoryContentsWS()
	const [content, setContent] = useState(fileContent.content)
	const [isFileNotChanged, setIsFileNotChanged] = useState(true)
	const { setAutoTestIsEnabled } = useStatusContext()
	const isBattleChallenge = challenge.tags.find((tag: Tag) => tag.name.toLowerCase() === ChallengeType.Battle.toLowerCase())

	// useEffect(() => {
	// 	if (!isFileNotChanged) {
	// 		window.addEventListener('beforeunload', (e) => {
	// 			e.preventDefault();
	// 			e.returnValue = true;
	// 		});
	// 	}
	// }, [isFileNotChanged])

	const handleOnClickArrowBack = useCallback(
		(filePath: string) => {
			let getFilePath = filePath
			const splitPath: string[] = filePath.split('/')
			if (splitPath.length > 2) {
				// means navigating to sub-directories
				splitPath.pop() // for removing current file
				splitPath.pop() // for one step back
				getFilePath = splitPath.join('/')
			}
			handleDirOFileClick(getFilePath, 'file')
			clickCancelUpdateBtn()
		},
		[handleDirOFileClick, clickCancelUpdateBtn]
	)

	useEffect(() => {
		if (isUpdated) {
			useRetrieveFileContentRequest(
				fileContent.gameSlug,
				fileContent.challengeSlug,
				fileContent.path
			)
			clickCancelUpdateBtn()
		}
	}, [
		isUpdated,
		fileContent.challengeSlug,
		fileContent.gameSlug,
		fileContent.path,
		clickCancelUpdateBtn
	])

	const onClickArrowBack = (filePath: string) => {
		if (!isFileNotChanged) {
			// eslint-disable-next-line
			if (window.confirm("Changes that you've made may not be saved. Do you want to leave?") === true) {
				handleOnClickArrowBack(filePath)
			}
		}
		else {
			handleOnClickArrowBack(filePath)
		}
	}

	const clickCommitOnlyButton = () => {
		useUpdateFileRepositoryContentsRequest(
			fileContent.gameSlug,
			fileContent.challengeSlug,
			content,
			fileContent.sha,
			fileContent.path
		)
		localStorage.setItem("autotest", AutoTestState.Disabled)
		setAutoTestIsEnabled(false)
	}

	const clickCommitAndTestButton = () => {
		useUpdateFileRepositoryContentsRequest(
			fileContent.gameSlug,
			fileContent.challengeSlug,
			content,
			fileContent.sha,
			fileContent.path
		)
		localStorage.setItem("autotest", AutoTestState.Enabled)
		setAutoTestIsEnabled(true)
	}

	const handleChangeFileContent = useCallback(
		(value: string, isChanged: boolean) => {
			setContent(value)
			setIsFileNotChanged(isChanged)
		},
		[]
	)

	const onClickFilePath = (backTrackedPath: string) => {
		if (!isFileNotChanged) {
			// eslint-disable-next-line
			if (window.confirm("Changes that you've made may not be saved. Do you want to leave?") === true) {
				handleDirOFileClick(backTrackedPath, null)
				goBackFileExplorer(false)
			}
		}
		else {
			handleDirOFileClick(backTrackedPath, null)
			goBackFileExplorer(false)
		}
	}


	return (
		<TableContainer component={Paper} sx={{ borderRadius: '0 0 20 20', marginTop: '20px' }}>
			<FileExplorerTopInfoRow challengeTitle={challenge.title} path={fileContent.path} type="file" latestCommit={latestCommit} onClickArrowBack={onClickArrowBack} onClickFilePath={onClickFilePath} />
			<Styled.CodeEditorContainer>
				<CodeEditor
					fileContent={fileContent}
					isEditable
					onChange={handleChangeFileContent}
				/>
			</Styled.CodeEditorContainer>
			<Styled.CodeEditorButtonsContainer>
				<Styled.ExplorerRowButton>
					<Styled.FileContentCommitAndTestButton
						variant='outlined'
						disabled={isFileNotChanged}
						onClick={clickCommitAndTestButton}
					>
						{isBattleChallenge ? "Publish" : "Commit & Test"}
					</Styled.FileContentCommitAndTestButton>
					<Styled.FileContentCommitButton
						className="tourCommitButton"
						variant='outlined'
						disabled={isFileNotChanged}
						onClick={clickCommitOnlyButton}
					>
						Commit Only
					</Styled.FileContentCommitButton>
					<Styled.FileContentCancelButton
						variant='outlined'
						onClick={() => onClickArrowBack(fileContent.path)}
					>
						Cancel
					</Styled.FileContentCancelButton>
				</Styled.ExplorerRowButton>
			</Styled.CodeEditorButtonsContainer>
		</TableContainer>
	)
}

export default FileContentUpdate
