import {
	autocompletion,
	closeBrackets,
	closeBracketsKeymap,
	completionKeymap,
} from '@codemirror/autocomplete'
import {
	history,
	historyKeymap,
	indentLess,
	indentMore,
	insertNewlineAndIndent,
	insertTab,
} from '@codemirror/commands'
import { java } from '@codemirror/lang-java'
import { javascript } from '@codemirror/lang-javascript'
import { python } from '@codemirror/lang-python'
import {
	LanguageSupport,
	bracketMatching,
	defaultHighlightStyle,
	foldGutter,
	foldKeymap,
	indentOnInput,
	indentUnit,
	syntaxHighlighting,
} from '@codemirror/language'
import { lintKeymap } from '@codemirror/lint'
import { highlightSelectionMatches, searchKeymap } from '@codemirror/search'
import { Compartment, EditorState, Extension } from '@codemirror/state'
import {
	crosshairCursor,
	drawSelection,
	dropCursor,
	highlightActiveLine,
	highlightActiveLineGutter,
	highlightSpecialChars,
	keymap,
	lineNumbers,
	rectangularSelection,
} from '@codemirror/view'
import { EditorView } from "codemirror"
import { useEffect, useMemo, useRef, useState } from 'react'

const LANGUAGES: { [key: string]: LanguageSupport } = {
	js: javascript(),
	py: python(),
	java: java(),
	cs: java(),
}

const useCodeMirror = (
	value: string,
	fileExtension: string,
	isEditable: boolean,
	extensions: Extension[],
) => {
	const ref = useRef<HTMLInputElement>()
	const [view, setView] = useState<null | EditorView>(null)

	const tabSize = useMemo(() => new Compartment(), [])
	const readOnly = useMemo(() => new Compartment(), [])
	const highlightActiveLineCompartment = useMemo(() => new Compartment(), [])
	const highlightActiveLineGutterCompartment = useMemo(
		() => new Compartment(),
		[],
	)

	const defaultKeymap = [
		{
			key: 'Tab',
			preventDefault: true,
			run: insertTab,
		},
		{
			key: 'Shift-Tab',
			preventDefault: true,
			run: indentLess,
		},
		{
			key: 'Enter',
			preventDefault: false,
			run: insertNewlineAndIndent,
		},
	]

	const pythonKeymap = [
		{
			key: 'Tab',
			preventDefault: true,
			run: indentMore,
		},
		{
			key: 'Shift-Tab',
			preventDefault: true,
			run: indentLess,
		},
	]

	// This is the same as the codemirror basicSetup, excluding
	// active line and gutter highlighting
	const customBasicSetup: Extension = (() => [
		lineNumbers(),
		highlightSpecialChars(),
		history(),
		foldGutter(),
		drawSelection(),
		dropCursor(),
		EditorState.allowMultipleSelections.of(true),
		indentOnInput(),
		syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
		bracketMatching(),
		closeBrackets(),
		autocompletion(),
		rectangularSelection(),
		crosshairCursor(),
		highlightSelectionMatches(),
		keymap.of([
			...closeBracketsKeymap,
			...defaultKeymap,
			...searchKeymap,
			...historyKeymap,
			...foldKeymap,
			...completionKeymap,
			...lintKeymap,
		]),
	])()

	useEffect(() => {
		const editorView = new EditorView({
			doc: value,
			extensions: [
				customBasicSetup,
				keymap.of(fileExtension === 'py' ? pythonKeymap : defaultKeymap),
				LANGUAGES[fileExtension] ? LANGUAGES[fileExtension] : javascript(),
				tabSize.of(EditorState.tabSize.of(4)),
				readOnly.of(EditorState.readOnly.of(true)),
				indentUnit.of(' '.repeat(4)),
				highlightActiveLineCompartment.of([]),
				highlightActiveLineGutterCompartment.of([]),
				...extensions,
			],
			parent: ref.current,
		})

		setView(editorView)

		editorView.dispatch({
			effects: [
				readOnly.reconfigure(EditorState.readOnly.of(!isEditable)),
				highlightActiveLineCompartment.reconfigure(
					isEditable ? highlightActiveLine() : [],
				),
				highlightActiveLineGutterCompartment.reconfigure(
					isEditable ? highlightActiveLineGutter() : [],
				),
			],
		})

		return () => {
			editorView.destroy()
			setView(null)
		}
		// eslint-disable-next-line
	}, [value, readOnly, isEditable, fileExtension, tabSize])

	return { ref, view }
}

export default useCodeMirror
