import { Box } from '@mui/material'
import {
	DataGrid,
	GridColDef,
	GridFilterModel,
	GridSlotsComponentsProps,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useState } from 'react'
import exportToCSV from '../../../../../../shared/helpers/exportToCSV'
import * as Styled from './CompanyGamePlayersTableStyles'

declare module '@mui/x-data-grid' {
	// eslint-disable-next-line no-unused-vars
	interface ToolbarPropsOverrides {
		company: Company
		selectedRows: CompanyPlayerActivityInfoRow[]
	}
}

export function renderStatus(params: any) {
	return (
		<Styled.StatusBarContainer
			sx={{
				backgroundColor:
					// eslint-disable-next-line no-nested-ternary
					params.value === 'Completed'
						? 'rgba(181, 217, 157, 1)'
						: params.value === 'Started'
							? 'rgba(253, 171, 90, 1)'
							: 'rgba(241, 94, 90, 1)',
			}}
		>
			{params.value}
		</Styled.StatusBarContainer>
	)
}

const columns: GridColDef[] = [
	{ field: 'playerEmail', headerName: 'Player', flex: 1 },
	{ field: 'playerDepartment', headerName: 'Department', flex: 1 },
	{
		field: 'completionStatus',
		headerName: 'Completion',
		flex: 1,
		headerAlign: 'center',
		renderCell: renderStatus,
		headerClassName: 'completionStatusHeader',
	},
]

function CustomToolbar({
	rows,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) {
	return (
		<GridToolbarContainer sx={{ height: '45px' }}>
			<Box sx={{ flexGrow: 1 }} />
			<GridToolbarFilterButton />
			<Styled.ExportTooltip title='Export CSV'>
				<Styled.BarExport
					onClick={() => exportToCSV(rows, 'game-completion-data.csv')}
					color='primary'
				>
					<Styled.SaveAltIcon />
				</Styled.BarExport>
			</Styled.ExportTooltip>
		</GridToolbarContainer>
	)
}

function CompanyGamePlayersTable({
	companyData,
	gamesActivityData,
}: CompanyGamePlayersTableProps) {
	const [pageSize, setPageSize] = useState(20)
	const rows: {
		playerEmail: string
		playerDepartment: string
		completionStatus: string
	}[] = []
	const [filterSelectionModel, setFilterSelectionModel] =
		useState<GridFilterModel>()

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	Object.entries(gamesActivityData.inactive_players as any).forEach(
		// eslint-disable-next-line no-unused-vars
		([key, value]: [string, any]) => {
			const department = companyData.players.find(
				(data: any) => data.email === value.email,
			)?.department?.name
			rows.push({
				playerEmail: value.email,
				playerDepartment:
					department === '' || department === undefined ? '-' : department,
				completionStatus: 'Not started',
			})
		},
	)
	Object.entries(gamesActivityData.completed_players as any).forEach(
		// eslint-disable-next-line no-unused-vars
		([key, value]: [string, any]) => {
			const department = companyData.players.find(
				(data: any) => data.email === value.email,
			)?.department?.name
			rows.push({
				playerEmail: value.email,
				playerDepartment:
					department === '' || department === undefined ? '-' : department,
				completionStatus: 'Completed',
			})
		},
	)
	Object.entries(gamesActivityData.eligible_players as any).forEach(
		// eslint-disable-next-line no-unused-vars
		([key, value]: [string, any]) => {
			const playerNotStarted = !rows.some(
				(data: any) => data.playerEmail === value.email,
			)
			if (playerNotStarted) {
				const department = companyData.players.find(
					(data: any) => data.email === value.email,
				)?.department?.name
				rows.push({
					playerEmail: value.email,
					playerDepartment:
						department === '' || department === undefined ? '-' : department,
					completionStatus: 'Started',
				})
			}
		},
	)
	const statusOrder: { [key: string]: number } = {
		Completed: 1,
		Started: 2,
		'Not started': 3,
	}
	rows.sort(
		(a, b) =>
			(statusOrder[a.completionStatus] || 9) -
			(statusOrder[b.completionStatus] || 9),
	)

	return (
		<Styled.TableBox>
			<DataGrid
				style={{ fontSize: 16 }}
				getRowId={(row: any) => row.playerEmail + row.gameStatus}
				rows={rows}
				columns={columns}
				onRowCountChange={handlePageSizeChange}
				disableRowSelectionOnClick
				pageSizeOptions={[5, 10, 20, 50, 100]}
				autoHeight
				rowHeight={35}
				columnHeaderHeight={35}
				localeText={{
					MuiTablePagination: {
						labelRowsPerPage: '',
					},
				}}
				slots={{
					toolbar: CustomToolbar,
				}}
				slotProps={{
					toolbar: {
						rows,
					},
				}}
				pagination
				initialState={{
					pagination: {
						paginationModel: { pageSize },
					},
				}}
				filterModel={filterSelectionModel}
				onFilterModelChange={newFilterModel =>
					setFilterSelectionModel(newFilterModel)
				}
				sx={{
					'& .completionStatusHeader .MuiDataGrid-columnHeaderTitle': {
						paddingLeft: '55px',
					},
				}}
			/>
		</Styled.TableBox>
	)
}

export default CompanyGamePlayersTable
