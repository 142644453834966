import { Box, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LoadButtonWrapper } from '../../../enums'
import SecDimButton from '../SecDimButton/SecDimButton'
import ErrorIconName from './ErrorPageConstants'
import * as Styled from './ErrorPageStyles'
import theme from '../../../utils/theme'

function ErrorPage({
	className,
	errorTitle = '',
	errorMessage = 'Something broke and an error has ocurred, Kindly refresh the page or contact the admin team',
	errorIconName = 'Default',
	loadButtonWrapper = 'default', // either default or hidden (default means it will display button)
	type = "page",  // either page or dialog (dialog if this component is used inside a MUI dialog component)
}: ErrorPageProps) {
	const navigate = useNavigate()

	/**
	 * CloudOff = Game could not be forked
	 * VideogameAssetOff = Could not find anything related to objects (Ex: Games or Challenges)
	 * SyncDisabled = An error ocurred while loading data.
	 * Dangerous = Something goes wrong. (ex: 500 Internal Server Error)
	 * Block = Authentication Error. (ex: unauthorized user)
	 * LockClock = Service/Session is expired (ex: game attempt is expired or )
	 * Warning = Service is unavailable
	 * Construction = Work in progress
	 *
	 * https://mui.com/components/material-icons
	 */

	const goToHomePage = () => {
		navigate('/')
	}

	const refresh = () => {
		window.location.reload()
	}

	return (
		<Container className={className}>
			<Styled.ErrorBox
				sx={
					type === 'dialog'
						? {
							[theme.breakpoints.up('md')]: {
								marginTop: '5%',
								minHeight: '30vh',
							},
							}
						: {}
				}
			>
				<Styled.ErrorPageIcon>{ErrorIconName[errorIconName]}</Styled.ErrorPageIcon>
				<Styled.ErrorTitle variant='h1'>{errorTitle}</Styled.ErrorTitle>
				<Styled.ErrorSubtitle variant='h5'>{errorMessage}</Styled.ErrorSubtitle>
				{loadButtonWrapper === LoadButtonWrapper.Default && (
					<Styled.ButtonWrapper>
						<SecDimButton rounded variant='outlined' onClick={goToHomePage}>
							Homepage
						</SecDimButton>
						<Box ml={2} />
						<SecDimButton rounded variant='outlined' onClick={refresh}>
							Reload
						</SecDimButton>
					</Styled.ButtonWrapper>
				)}
			</Styled.ErrorBox>
		</Container>
	)
}

export default ErrorPage
