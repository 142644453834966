import { SaveAlt } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 56px',
	paddingRight: '0',
	width: '100%',

	'& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
		outline: 'none !important',
	},

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	'& .MuiDataGrid-footerContainer': {
		height: '52px !important',
		minHeight: '0px',
	},

	'& .MuiTablePagination-displayedRows': {
		marginBlockStart: '0px !important',
		marginBlockEnd: '0px !important',
	},

	'& .css-7g6wwl-MuiInputBase-root-MuiTablePagination-select': {
		marginRight: '8px',
	},

	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer',
	},
}))

export const StatusBarContainer = styled('div')(() => ({
	width: '100%',
	height: 30,
	borderRadius: 2,
	margin: '5px 0',
	position: 'relative',
	bottom: '3px',
	color: '#ffffff',
	fontWeight: 'bold',
	textAlign: 'center',
}))

export const ExportTooltip = styled(Tooltip)(() => ({}))

export const SaveAltIcon = styled(SaveAlt)(() => ({}))

export const BarExport = styled(IconButton)(() => ({
	'&:hover': {
		backgroundColor: 'transparent !important',
		boxShadow: 'none',
		color: 'inherit',
	},
}))
