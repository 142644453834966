import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomDialog = styled(Dialog)({
	width: '90%',

	'& .MuiDialog-paper': {
		width: '90%',
		maxWidth: '1050px',
	},
})

export const CompanyGameGridDialogItem = styled(Grid)({
	width: '100%',
	maxWidth: '100%',
})

export const CompanyGameTableContainer = styled(Grid)(() => ({
	margin: '10px 0px 15px 0px;',
	width: '100%',
	maxWidth: '100%',
}))

export const CompanyGameGraphTableItem = styled(Grid)(() => ({
	width: '100%',
	maxWidth: '100%',
}))

export const DialogTitleText = styled(DialogTitle)({
	fontWeight: 'bold',
	textAlign: 'center',
	fontSize: '1.875rem',
	lineHeight: 1.235,
})

export const CompanyGameDialogContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 40,

	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})
