import { useCompanyDashboardContext } from '../../../../api/hooks'
import * as Styled from './CompanyGameDialogStyles'
import CompanyGamePlayersTable from './components/CompanyPlayerRepositoriesTable/CompanyGamePlayersTable'

function CompanyGameDialog({
	className,
	companyData,
	gamesActivityData,
}: CompanyGameDialogProps) {
	const { openCompanyGameDialogInfo, setOpenCompanyGameDialogInfo } =
		useCompanyDashboardContext()

	const handleCloseDialog = () => {
		setOpenCompanyGameDialogInfo({
			isDialogShown: false,
			gameTitle: '',
			gameSlug: '',
			gameDepartments: [] as any[],
		})
	}

	return (
		<Styled.CustomDialog
			maxWidth='lg'
			onClose={handleCloseDialog}
			open={openCompanyGameDialogInfo.isDialogShown}
			className={className}
		>
			<Styled.AbsoluteClose onClick={handleCloseDialog} />
			<Styled.CompanyGameDialogContainer container direction='column'>
				<Styled.CompanyGameGridDialogItem item sx={{ paddingBottom: '10px' }}>
					<Styled.DialogTitleText>
						{openCompanyGameDialogInfo.gameTitle} Report
					</Styled.DialogTitleText>
				</Styled.CompanyGameGridDialogItem>
				<Styled.CompanyGameGridDialogItem item>
					<Styled.CompanyGameGraphTableItem container direction='row'>
						<Styled.CompanyGameGraphTableItem item xs={12} mdm={12}>
							<CompanyGamePlayersTable
								companyData={companyData!}
								gamesActivityData={gamesActivityData}
							/>
						</Styled.CompanyGameGraphTableItem>
					</Styled.CompanyGameGraphTableItem>
				</Styled.CompanyGameGridDialogItem>
			</Styled.CompanyGameDialogContainer>
		</Styled.CustomDialog>
	)
}

export default CompanyGameDialog
