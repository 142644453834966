import {
	Box,
	Divider,
	List,
	ListItemButton,
	Menu,
	Tooltip,
	Typography,
} from '@mui/material'
import {
	DataGrid,
	GridColDef,
	GridFilterModel,
	GridRowSelectionModel,
	GridSlotsComponentsProps,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useContext, useEffect, useState } from 'react'
import { OpenIDContext } from '../../../../api/auth'
import { useCompanyDashboardContext } from '../../../../api/hooks'
import playBackendRequestHandler from '../../../../api/playBackendRequestHandler'
import { Department } from '../../../../enums'
import * as Styled from './CompanyPlayersActivitiesTableStyles'

// Required for allow custom toolbar to have custom props
// See: https://mui.com/x/react-data-grid/components/#custom-slot-props-with-typescript
declare module '@mui/x-data-grid' {
	// eslint-disable-next-line no-unused-vars
	interface ToolbarPropsOverrides {
		company: Company
		selectedRows: CompanyPlayerActivityInfoRow[]
	}
}

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'Rank',
		align: 'center',
		headerAlign: 'center',
		flex: 25,
		filterable: false,
	},
	{ field: 'playerEmail', headerName: 'Player', flex: 150 },
	{ field: 'department', headerName: 'Department', flex: 150 },
	{
		field: 'attempts',
		headerName: 'Attempts',
		type: 'number',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
]

function CustomToolbar({
	company,
	assignDepartment,
	numSelected,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) {
	const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState<null | HTMLElement>(
		null,
	)
	const [assignDepartmentMenuOpen, setAssignDepartmentMenuOpen] = useState(false)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setMainMenuAnchorEl(event.currentTarget)
	}

	const handleMainMenuClose = () => {
		setMainMenuAnchorEl(null)
	}

	return (
		<GridToolbarContainer sx={{ height: '45px' }}>
			<Typography fontSize={20} paddingLeft={1}>
				Team Engagements
				<Tooltip
					title='Click on each row to view the user details'
					placement='top-start'
				>
					<Styled.HelpIcon />
				</Tooltip>
			</Typography>
			<GridToolbarFilterButton/>
			<Box sx={{ flexGrow: 1 }} />
			<GridToolbarExport/>
			<Box sx={{ marginRight: 1}}/>
			{numSelected !== 0 ? (
				<>
					<Styled.UserNavLink onClick={handleClick}>
						<Styled.CustomEllipsis />{' '}
					</Styled.UserNavLink>
					<Menu
						anchorEl={mainMenuAnchorEl}
						open={Boolean(mainMenuAnchorEl)}
						onClose={handleMainMenuClose}
						sx={{
							maxHeight: 350,
							width: 'auto',
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<Styled.UserNavMenuItem
							onClick={() => setAssignDepartmentMenuOpen(!assignDepartmentMenuOpen)}
						>
							<Styled.CustomAssignment />{' '}
							<Styled.UserNavLinkText>Assign Department</Styled.UserNavLinkText>
						</Styled.UserNavMenuItem>
						{assignDepartmentMenuOpen && (
							<List>
								<Divider />
								{company?.departments.map(department => (
									<ListItemButton
										key={department.slug}
										sx={{
											minWidth: '225px',
											textAlign: 'right',
											justifyContent: 'flex-end',
										}}
										onClick={() => {
											assignDepartment(department.slug)
											handleMainMenuClose()
											setAssignDepartmentMenuOpen(!assignDepartmentMenuOpen)
										}}
									>
										<Styled.UserNavLink>{department.name}</Styled.UserNavLink>
									</ListItemButton>
								))}
								<Divider />
							</List>
						)}
						<Styled.UserNavMenuItem
							onClick={() => {
								assignDepartment(null)
								handleMainMenuClose()
							}}
						>
							<Styled.CustomDelete />{' '}
							<Styled.UserNavLinkText>Remove Department</Styled.UserNavLinkText>
						</Styled.UserNavMenuItem>
					</Menu>
				</>
			) : null}
		</GridToolbarContainer>
	)
}

function CompanyPlayersActivitiesTable({
	className,
	company,
	companyPlayersActivitiesData,
	department,
	refetchData,
	setPermissionAlert,
	setPermissionAlertOpen,
}: CompanyPlayersActivitiesTableProps) {
	const { setOpenCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const [pageSize, setPageSize] = useState(10)
	// Initialised to dummy data for skeleton loading animation. Will be updated with real data on load. 
	const [rows, setRows] = useState<CompanyPlayerActivityInfoRow[]>(
		Array.from({ length: pageSize }, (_, index) => ({
			id: `row-${index}`,
			playerEmail: 'Lorem ipsum',
			guid: 'Lorem ipsum',
			department: 'Lorem ipsum',
			attempts: 0,
		})),
	)
	const [rowSelectionModel, setRowSelectionModel] =
		useState<GridRowSelectionModel>([])
	const [filterSelectionModel, setFilterSelectionModel] =
		useState<GridFilterModel>({
			items:
				department && department !== Department.Default
					? [{ field: 'department', operator: 'contains', value: department }]
					: [],
		})

	const [selectedRows, setSelectedRows] = useState<
		CompanyPlayerActivityInfoRow[]
	>([])
	const { profile } = useContext(OpenIDContext)

	const onClickTableRow = (rowData: any) => {
		const companyPlayerActivities = companyPlayersActivitiesData?.find(
			data => data.player_email === rowData.row.playerEmail,
		)
		setOpenCompanyPlayerProfileDialogInfo({
			isDialogShown: true,
			playerEmail: companyPlayerActivities!.player_email,
			playerUsername: companyPlayerActivities!.player_username,
			playerGuid: companyPlayerActivities!.guid,
		})
	}

	useEffect(() => {
		if (companyPlayersActivitiesData !== null) {
			const tempRows = []
			for (let index = 0; index < companyPlayersActivitiesData.length; index += 1) {
				let rank: string
				if (index === 0) rank = '🥇'
				else if (index === 1) rank = '🥈'
				else if (index === 2) rank = '🥉'
				else rank = String(index + 1)
				tempRows.push({
					id: rank,
					guid: companyPlayersActivitiesData[index].guid,
					playerEmail: companyPlayersActivitiesData[index].player_email,
					department: companyPlayersActivitiesData[index].player_department
						? companyPlayersActivitiesData[index].player_department
						: '-',
					attempts: companyPlayersActivitiesData[index].total_challenge_attempts,
				})
			}
			setRows(tempRows)
		}
	}, [companyPlayersActivitiesData])

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	useEffect(() => {
		setFilterSelectionModel({
			items:
				department && department !== Department.Default
					? [{ field: 'department', operator: 'contains', value: department }]
					: [],
		})
	}, [department])

	const assignDepartment = (option: string | null) => {
		if (profile.restrictions.hasAddCompanyDepartmentPermission) {
			if (selectedRows && selectedRows.length !== 0) {
				const users: { guid: string; department: string | null }[] = []
				selectedRows?.forEach(row => {
					users.push({ guid: row.guid, department: option })
				})

				const data = {
					username: profile.guid,
					users,
				}

				playBackendRequestHandler(
					'batchUserUpdate',
					data,
					undefined,
					undefined,
				).then(resp => {
					if (resp.status === 200) {
						setSelectedRows([])
						setRowSelectionModel([])
						refetchData()
					}
				})
			}
		} else {
			setPermissionAlertOpen(true)
			setPermissionAlert('Upgrade your account to manage company departments.')
		}
	}

	return (
		<Styled.TableBox className={className}>
			<DataGrid
				sx={{
					'& .MuiDataGrid-footerContainer': {
						height: '67px',
						minHeight: '67px',
						overflow: 'hidden',
					},
				}}
				loading={companyPlayersActivitiesData === null}
				checkboxSelection
				disableRowSelectionOnClick
				rows={rows}
				columns={columns}
				onRowCountChange={handlePageSizeChange}
				pageSizeOptions={[10, 25, 50, 100]}
				autoHeight
				rowHeight={40}
				localeText={{
					MuiTablePagination: {
						labelRowsPerPage: '',
					},
				}}
				columnHeaderHeight={40}
				pagination
				onRowClick={onClickTableRow}
				initialState={{
					pagination: {
						paginationModel: { pageSize },
					},
				}}
				filterModel={filterSelectionModel}
				onFilterModelChange={newFilterModel => setFilterSelectionModel(newFilterModel)}
				slots={{
					toolbar: CustomToolbar,
				}}
				slotProps={{
					toolbar: {
						company,
						selectedRows,
						assignDepartment,
						numSelected: selectedRows.length,
						refetchData,
					},
					loadingOverlay: {
						variant: 'skeleton',
						noRowsVariant: 'skeleton',
					},
				}}
				onRowSelectionModelChange={(newRowSelectionModel, details) => {
					const selectedRowsData: CompanyPlayerActivityInfoRow[] =
						newRowSelectionModel.map(id => {
							const row = details.api.getRow(id)
							return row !== null ? row : []
						})
					setRowSelectionModel(newRowSelectionModel)
					setSelectedRows(selectedRowsData)
				}}
				rowSelectionModel={rowSelectionModel}
			/>
		</Styled.TableBox>
	)
}
export default CompanyPlayersActivitiesTable
