/* eslint-disable no-unused-vars */
import { EditorView, ViewUpdate } from '@codemirror/view'

export type OnChange = (
	value: string,
	isChanged: boolean,
	viewUpdate: ViewUpdate
) => void

const onUpdate = (initialValue: string, onChange?: OnChange) =>
	EditorView.updateListener.of((viewUpdate: ViewUpdate) => {
		if (viewUpdate.docChanged) {
			const { doc } = viewUpdate.state
			const value = doc.toString()

			if (onChange) {
				onChange(value, value === initialValue, viewUpdate)
			}
		}
	})

export default onUpdate
