import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TableBody, TableContainer, Table, Paper, Box } from "@mui/material"
import * as Styled from './FileExplorerStyles'
import { useRetrieveFileContentWS } from "../../../../api/hooks";
import FileContent from "../FileContent/FileContent";
import FileExplorerTopInfoRow from "../../../../shared/components/FileExplorer/FileExplorerTopInfoRow/FileExplorerTopInfoRow";

function FileExplorer({ challenge, dirContents, handleDirOFileClick, continueTour, editable }: FileExplorerProps) {
	const { gameSlug, challengeSlug } = useParams() as {
		gameSlug: string
		challengeSlug: string
	} 
    // here commitListBundle means it contains all commits from all concurrent running challenges
    const commitListForCurrentlyRunningChallenge = useSelector((state: any) => state.commitListBundle).find((_commitList: any) =>
        _commitList.gameSlug === gameSlug &&
        _commitList.challengeSlug === challengeSlug)
    const { loading, fileContentsWSResponse } = useRetrieveFileContentWS(dirContents[0].gameSlug, dirContents[0].challengeSlug)
    const [fileContent, setFileContent] = useState<FileProps>(fileContentsWSResponse)
    const [displayFileContent, setDisplayFileContent] = useState<boolean>(false)
    const [loadDirContents, setLoadDirContents] = useState<DirOrFileProps[]>([])

    // here get commit list of the current running challenge from commitListBundle
    let commitList: Commit[] | null = null
    if (commitListForCurrentlyRunningChallenge && commitListForCurrentlyRunningChallenge.commits) {
        commitList = commitListForCurrentlyRunningChallenge.commits
    }

    useEffect(() => {
        setLoadDirContents(dirContents)
    }, [dirContents])

    useEffect(() => {
        setFileContent(fileContentsWSResponse)
    }, [fileContentsWSResponse])

    const onClickDirOFile = (content: DirOrFileProps) => {
        if (content.type === "file") {
            setDisplayFileContent(true)
        }
        handleDirOFileClick(content.path, content.type)
    }

    const onClickArrowBack = (filePath: string) => {
        let getFilePath = filePath
        const splitPath: string[] = filePath.split("/")
        if (splitPath.length > 2) { // means navigating to sub-directories
            splitPath.pop() // for removing current file
            splitPath.pop() // for one step back
            getFilePath = splitPath.join("/")
        }
        else {
            getFilePath = "/"
        }
        handleDirOFileClick(getFilePath, null)
    }

    const goBackFileExplorer = (isClickedGoBackBtn: boolean) => {
        setFileContent({ content: "", gameSlug: "", challengeSlug: "", sha: "", path: "", repository: "" })
        setDisplayFileContent(isClickedGoBackBtn)
        setLoadDirContents([])
    }

    const onClickFilePath = (backTrackedPath: string) => {
        handleDirOFileClick(backTrackedPath, null)
    }

    useEffect(() => {
        if (displayFileContent && !loading && continueTour) {
            continueTour(true)
        }
    }, [continueTour, displayFileContent, loading])
    
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                displayFileContent && !loading ?
                <Box className="tourFileExplorer">
                    <FileContent
                        challenge={challenge}
                        fileContent={fileContent}
                        dirContents={loadDirContents}
                        latestCommit={commitList && commitList[0] ? commitList[0] : null}
                        handleDirOFileClick={handleDirOFileClick}
                        goBackFileExplorer={goBackFileExplorer}
                        continueTour={continueTour}
                        editable={editable}
                    />
                </Box > :
                    <TableContainer className="tourFileExplorer" component={Paper} sx={{ borderRadius: '0 0 20 20', marginTop: '25px' }}>
                        {
                            dirContents && dirContents.length > 0 ? <FileExplorerTopInfoRow challengeTitle={challenge.title} path={dirContents[0].path} container={dirContents[0].container} type="dir" latestCommit={commitList && commitList[0] ? commitList[0] : null} onClickArrowBack={onClickArrowBack} onClickFilePath={onClickFilePath} /> : null
                        }
                        <Box sx={{ height: '420px', overflow: 'scroll' }}>
                            <Table aria-label='simple table'>
                                <TableBody>
                                    {loadDirContents.map((content) => (
                                        <Styled.ExplorerRow
                                            key={content.name}
                                            onClick={() => { onClickDirOFile(content) }}
                                        >
                                            <Styled.TableNameColumn >
                                                {content.type === "dir" ? <Styled.CustomFolder /> : <Styled.CustomInsertDriveFile />}
                                                <Styled.ExplorerItem>
                                                    {content.name}
                                                </Styled.ExplorerItem>
                                            </Styled.TableNameColumn>
                                        </Styled.ExplorerRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </TableContainer>
            }
        </>
    )
}

export default FileExplorer