import { Close, Info } from '@mui/icons-material'
import { Button, Dialog, DialogTitle, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomDialog = styled(Dialog)({
	width: '90%',

	'& .MuiDialog-paper': {
		width: '90%',
		maxWidth: '1050px',
	},
})

export const PlayerActivityGridDialogItem = styled(Grid)({
	width: '100%',
	maxWidth: '100%',
})

export const PlayerActivityGridDialogButtonItem = styled(Grid)({})

export const PlayerRankingsTitle = styled(Typography)(({ theme }) => ({
	marginTop: 10,
	padding: '0px 0px 10px 75px',
	fontWeight: 600,
	textAlign: 'center',
	fontSize: '13.5px',
	color: '#464343',
	position: 'relative',

	[theme.breakpoints.down('sm')]: {
		padding: '0px 0px 10px 0px',
		textAlign: 'center',
		marginTop: 0,
	},
}))

export const PlayerActivityGraphTableContainer = styled(Grid)(() => ({
	margin: '10px 0px 15px 0px;',
	width: '100%',
	maxWidth: '100%',
}))

export const PlayerActivityGraphTableItem = styled(Grid)(() => ({
	width: '100%',
	maxWidth: '100%',
}))

export const DialogTitleText = styled(DialogTitle)({
	fontWeight: 'bold',
	textAlign: 'center',
	fontSize: '1.875rem',
	lineHeight: 1.235,
})

export const PlayerActivityDialogContainer = styled(Grid)(({ theme }) => ({
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 40,

	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const PlayerActivityDialogUserActionsContainer = styled(Grid)(
	({ theme }) => ({
		width: '100%',
		maxWidth: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 40,
		gap: 10,

		[theme.breakpoints.down('sm')]: {
			width: 'auto',
			height: 'auto',
		},
	}),
)

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const RemoveButton = styled(Button)({
	borderColor: '#ff000073',
	backgroundColor: '#FFF',
	color: '#ff0000de',
	'&:hover': {
		color: '#FFF',
		backgroundColor: '#ff0000de',
		borderColor: '#ff000073',
	},
})

export const HelpIcon = styled(Info)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: '13.5px',
	position: 'absolute',
	top: '3px',
	marginLeft: '3px',
	cursor: 'pointer',
}))
