import {
	Table,
	TableCell,
	Box,
	TableRow,
	Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Folder, InsertDriveFile } from '@mui/icons-material'

export const FileExplorerTable = styled(Table)({
	minWidth: 700,
})

export const TableIconColumn = styled(TableCell)({
	width: 0,
	position: 'relative',
	padding: '35px',
	paddingRight: '0px!important',
	'& > svg': {
		position: 'absolute',
		top: '21px'
	}
})


export const CustomFolder = styled(Folder)(({ theme }) => ({
	color: theme.palette.primary.main,
}))

export const CustomInsertDriveFile = styled(InsertDriveFile)(({ theme }) => ({
	color: theme.palette.primary.main
}))

export const TableNameColumn = styled(TableCell)(({ theme }) => (
	{
		display: 'flex',
		flexDirection: 'row',
		gap: '16px',
		paddingLeft: '25px',
		[theme.breakpoints.down('md')]: {
			paddingLeft: '25px'
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '50px'
		},
	}
))

export const TableHead = styled(Box)(({ theme }) => ({
	width: '100%',
	color: theme.palette.primary.main,
	marginLeft: 20,
}))

export const LastCommit = styled(Box)({
	alignItems: 'center',
	justifyContent: 'flex-end',
	marginLeft: 5,
})

export const ExplorerRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: theme.palette.primary.contrastText,
		cursor: 'pointer',
	},
}))

export const ExplorerItem = styled(Typography)(({ theme }) => ({
	'&:hover': {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}))

export const EditIconWrapper = styled('div')({
	width: 28,
	height: 28,
	cursor: 'pointer',

	'& > svg': {
		width: '100%',
		height: '100%',
	},
})
